import "./index.scss";
import React from "react";

import axios from "axios";
import moment from "moment";
import rpcClient from "../../../modules/rpcClientModule";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { FilteredCustomTable } from "../../../components/customComponents/Table2";
import Checkbox from "../../../components/customComponents/Checkbox";
import Spinner from "../../../components/customComponents/Spinner";
import Button from "../../../components/customComponents/Button";

import CreateTeamModal from "../../../components/modals/CreateTeamModal";
import MultiSelectUsers from "../../../components/modals/MultiSelectUsersModal";
import CreateTeamChannelModal from "../../../components/modals/CreateTeamChannelModal";

import * as basicStylesModule from "../../../../src/modules/basicStylesModule";
import { animateBox } from "../../../modules/componentAnimation";
import animateModule from "../../../modules/animateModule";

import languages from "../../../languages";

const AdminTeams = (props) => {
    const curLocation = useLocation();

    const languageSelector = useSelector(state => state?.siteFunctions?.language ?? 'en');
    const userDataSelector = useSelector(state => state?.userData ?? {});
    const timestampSelector = useSelector(state => state?.timestamp ?? null);

    const [data, setData] = React.useState([]);
    const [users, setUsers] = React.useState([]);
    const [orders, setOrders] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false);

    const getTeams = () => {
        rpcClient({
            action: "call",
            method: "teams.getAll",
            params: {
                filters: filters.length ? filters : [],
                allTeams: true,
            },
            callback: d => setData(d.data)
        }).then(teams => {
        })
    };

    const getUsers = () => {

        rpcClient({
            action: "call",
            method: "users.getAll",
            params: {
                filters : filters.length ? filters : []
            },
            callback: d => setUsers(d)
        })
    };

    const orderData = (d, headers) => {
        if (!orders) return d;

        let headerIndex = headers.indexOf(orders.name);

        if (headerIndex === -1) return d;

        let asc = orders.order === "asc" ? 1 : -1;
        let desc = orders.order === "asc" ? -1 : 1;

        return [...d].sort((a, b) => {
            let a1 = a[headerIndex];
            let b1 = b[headerIndex];

            if (!a1 || !b1) return 0;

            if (Number(a1?.text) && Number(b1?.text)) {
                return Number(a1?.text) > Number(b1?.text) ? asc : desc;
            } else {
                return a1?.text > b1?.text ? asc : desc;
            };
        });
    };

    const deleteTeam = (id) => {
        rpcClient({
            action: "call",
            method: "teams.deleteTeam",
            params: {
                ID: id
            },
        }).then(() => {
            getTeams();
        })
    }

    const getUsersUsername = id => {
        if(!users || !users?.data?.length) return;
        let wantedUser = users?.data?.find(el => el.ID === id);
        return wantedUser.Username
    }

    React.useEffect(() => {
        getTeams();
    }, [filters])

    React.useEffect(() => {
        getUsers();
    }, [])

    return <div className={`component__admin__teams`}>

        <div className="component__admin__teams__top">
            <p onClick={(e) => animateBox(e, <CreateTeamModal user={userDataSelector} onChange={getTeams} />)}>{languages[languageSelector].addTeam}</p>
        </div>

        {data.length && <FilteredCustomTable
            filterCB={c => setFilters(c)}
            id='table'
            accent='#000'
            dropdownBackground='#000'
            inputBottomBorderColor='#FFF'
            placeholderStyle={{ backgroundColor: '#000' }}
            theme='dark'
            filterHeadStyle={{ backgroundColor: 'rgba(25, 35, 67, 0.2)' }}
            tableHeadStyle={{ backgroundColor: 'rgba(25, 35, 67, 0.2)' }}
            buttonStyle={{ border: '1px solid #FFF', backgroundColor: '#000' }}
            // style={{colors: index % 2 === 0 ? '#1923438e' : '#192343'}}
            customColumns={["auto", "auto", "auto", "auto", "auto"]}
            orderCB={o => setOrders(o)}
            headers={["ID", "Name", "Participants", "CreatedBy", "Channels"]}
            filters={[
                { name: "Name", friendlyName: "Name", type: "string" },
            ]}
            data={(() => {
                let out = [];

                if (!data) return [[{ keyID: "noData-spinner", type: "spinner" }]];

                for (let item of data) {
                    out.push([
                        { keyID: item.ID, type: "text", text: item.ID },
                        { keyID: item.ID, type: "text", text: item.Name },
                        { keyID: item.ID, type: "text", text: item.Participants.length },
                        { keyID: item.ID, type: "text", text: getUsersUsername(item.CreatedBy) },
                        { keyID: item.ID, type: "custom", data: <div>{item.TeamChannels.map(el => <p style={{gap: '4px'}}>{el}</p>)}</div>},
                        {
                            keyID: String(item.ID), type: "groupNewline", group: [
                                {
                                    keyID: String(item.ID), type: "button", text: "Delete", triggerDropdown: true, triggerData: e => {
                                        return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                                            <p style={{ color: 'white' }}>Are you sure?</p>
                                            <br></br>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Button style={{ display: spinner ? 'none' : 'block', marginRight: '10px' }} accent='rgb(72, 82, 92)' value='YES' onClick={() => deleteTeam(item.ID)} />
                                                <Button style={{ display: spinner ? 'none' : 'block' }} accent='rgb(72, 82, 92)' value='NO' onClick={c => { e() }} />
                                                <div style={{ display: spinner ? 'block' : 'none' }}><Spinner color='white' width='32px' /></div>
                                            </div>
                                        </div>);
                                    },
                                },
                                {
                                    keyID: String(item.ID), type: "button", text: "Edit", triggerDropdown: true, onClick: e => {
                                        animateBox(e, <CreateTeamModal edit={item} user={userDataSelector} onChange={getTeams} />)
                                    }
                                },
                                {
                                    keyID: String(item.ID), type: "button", text: "Channels", triggerDropdown: true, onClick: e => {
                                        animateBox(e, <TeamChannelsModal user={userDataSelector} team={item} onChange={getTeams} />)
                                    }
                                }
                            ]
                        }
                    ])
                };

                out = orderData(out, ["Timelapse", "Clicks", "SOI", "CR(SOI)", "DOI", "CR(DOI)", "Daily Users", "Avg. Active Time", "Unique Buyers", "Total Revenue"])
                if (spinner) out.push([{ keyID: "data-spinner", type: "spinner" }]);
                if (out.length === 0) out.push([{ keyID: "noData-noData", type: "text", text: "Nothing to show..." }]);

                return out;
            })()}
        />}

    </div>
};





// ---- Team Channels logic below ----

const TeamChannelsModal = props => {

    const [teamChannels, setTeamChannels] = React.useState([]);
    const [users, setUsers] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false);
    const [orders, setOrders] = React.useState();
    const [filters, setFilters] = React.useState([]);

    const userDataSelector = useSelector(state => state?.userData ?? {});

    const getTeamChannels = () => {

        rpcClient({
            action: "call",
            method: "teamChannels.getAll",
            params: {
                filters: [{ name: "TeamID", op: "eq", value: props?.team?.ID }],
                allTeamChannels: true
            },
            callback: d => setTeamChannels(d.data)
        })
    };

    const getUsers = () => {

        rpcClient({
            action: "call",
            method: "users.getAll",
            params: {
                filters : filters.length ? filters : []
            },
            callback: d => setUsers(d)
        })
    };

    const orderData = (d, headers) => {
        if (!orders) return d;

        let headerIndex = headers.indexOf(orders.name);

        if (headerIndex === -1) return d;

        let asc = orders.order === "asc" ? 1 : -1;
        let desc = orders.order === "asc" ? -1 : 1;

        return [...d].sort((a, b) => {
            let a1 = a[headerIndex];
            let b1 = b[headerIndex];

            if (!a1 || !b1) return 0;

            if (Number(a1?.text) && Number(b1?.text)) {
                return Number(a1?.text) > Number(b1?.text) ? asc : desc;
            } else {
                return a1?.text > b1?.text ? asc : desc;
            };
        });
    };

    const deleteTeamChannel = (id) => {
        rpcClient({
            action: "call",
            method: "teamChannels.deleteTeamChannel",
            params: {
                ID: id
            },
        }).then(() => {
            getTeamChannels();
        })
    }

    const getUsersUsername = id => {
        if(!users || !users?.data?.length) return;
        let wantedUser = users?.data?.find(el => el.ID === id);
        return wantedUser.Username
    }

    React.useEffect(() => {
        getTeamChannels();
    }, [])

    React.useEffect(() => {
        getUsers();
    }, [])

    return <div className="teamChannelModal" onClick={() => props.onClose()}>
        <div className="teamChannelModal__closeBtn"><img src="/images/icon_close.svg" onClick={() => props.onClose()} /></div>
        <div className="teamChannelModal__wrapper" onClick={(e) => e.stopPropagation()}>
            <div className="teamChannelModal__wrapper__addChannel">
                <p onClick={(e) => animateBox(e, <CreateTeamChannelModal user={userDataSelector} teamID={props?.team?.ID} onChange={() => {
                if (props.onChange) {
                    props.onChange();
                }
                    getTeamChannels();
                }}  />)}>add Channel</p>
            </div>
            <p className="teamChannelModal__wrapper__headline">Team Channels in {props?.team?.Name}</p>
            {teamChannels && <FilteredCustomTable
                filterCB={c => setFilters(c)}
                id='table'
                accent='#000'
                dropdownBackground='#000'
                inputBottomBorderColor='#FFF'
                placeholderStyle={{ backgroundColor: '#000' }}
                theme='dark'
                filterHeadStyle={{ backgroundColor: 'rgba(25, 35, 67, 0.2)' }}
                tableHeadStyle={{ backgroundColor: 'rgba(25, 35, 67, 0.2)' }}
                buttonStyle={{ border: '1px solid #FFF', backgroundColor: '#000' }}
                customColumns={["auto", "auto", "auto", "auto"]}
                orderCB={o => setOrders(o)}
                headers={["ID", "Name", "Participants", "CreatedBy"]}
                filters={[
                    { name: "Name", friendlyName: "Name", type: "string" },
                ]}
                data={(() => {
                    let out = [];

                    if (!teamChannels) return [[{ keyID: "noData-spinner", type: "spinner" }]];

                    for (let item of teamChannels) {
                        out.push([
                            { keyID: item.ID, type: "text", text: item.ID },
                            { keyID: item.ID, type: "text", text: item.Name },
                            { keyID: item.ID, type: "text", text: item.Participants.length },
                            { keyID: item.ID, type: "text", text: getUsersUsername(item.CreatedBy) },
                            {
                                keyID: String(item.ID), type: "groupNewline", group: [
                                    {
                                        keyID: String(item.ID), type: "button", text: "Delete", triggerDropdown: true, triggerData: e => {
                                            return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                                                <p style={{ color: 'white' }}>Are you sure?</p>
                                                <br></br>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <Button style={{ display: spinner ? 'none' : 'block', marginRight: '10px' }} accent='rgb(72, 82, 92)' value='YES' onClick={() => deleteTeamChannel(item.ID)} />
                                                    <Button style={{ display: spinner ? 'none' : 'block' }} accent='rgb(72, 82, 92)' value='NO' onClick={c => { e() }} />
                                                    <div style={{ display: spinner ? 'block' : 'none' }}><Spinner color='white' width='32px' /></div>
                                                </div>
                                            </div>);
                                        },
                                    },
                                    {
                                        keyID: String(item.ID), type: "button", text: "Edit", triggerDropdown: true, onClick: e => {
                                            animateBox(e, <CreateTeamChannelModal edit={item} user={userDataSelector} onChange={() => {
                                                if (props.onChange) {
                                                    props.onChange();
                                                }
                                                getTeamChannels();
                                                }}  
                                            />)
                                        }
                                    },
                                    // {
                                    //     keyID: String(item.ID), type: "button", text: "Integrations", triggerDropdown: true, onClick: e => {
                                    //         animateBox(e, <TeamChannelsModal teamChannel={item} />)
                                    //     }
                                    // }
                                ]
                            }
                        ])
                    };

                    out = orderData(out, ["Timelapse", "Clicks", "SOI", "CR(SOI)", "DOI", "CR(DOI)", "Daily Users", "Avg. Active Time", "Unique Buyers", "Total Revenue"])
                    if (spinner) out.push([{ keyID: "data-spinner", type: "spinner" }]);
                    if (out.length === 0) out.push([{ keyID: "noData-noData", type: "text", text: "Nothing to show..." }]);

                    return out;
                })()}
            />}
        </div>
    </div>
}






export default AdminTeams;