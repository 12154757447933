import "./index.scss";
import React from "react";

import axios from "axios";
import moment from "moment";
import rpcClient from "../../../modules/rpcClientModule";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { FilteredCustomTable } from "../../../components/customComponents/Table2";
import Checkbox from "../../../components/customComponents/Checkbox";
import Spinner from "../../../components/customComponents/Spinner";
import Button from "../../../components/customComponents/Button";


import * as basicStylesModule from "../../../../src/modules/basicStylesModule";
import {animateBox} from "../../../modules/componentAnimation"


import animateModule from "../../../modules/animateModule";

const Users = (props) => {
    const curLocation = useLocation();
    const curNavigate = useNavigate();

    const userDataSelector = useSelector(state => state?.userData ?? {});
    const timestampSelector = useSelector(state => state?.timestamp ?? null);

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".root__content"));

    const [data, setData] = React.useState([]);
    const [orders, setOrders] = React.useState();
    const [selectedUsers, setSelectedUsers] = React.useState([]);
    const [filters, setFilters] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false);

    const getUsers = () => {

        rpcClient({
            action: "call",
            method: "users.getAll",
            params: {
                filters : filters.length ? filters : []
            },
            callback: d => setData(d)
        })
    };

    const orderData = (d, headers) => {
        if (!orders) return d;

        let headerIndex = headers.indexOf(orders.name);

        if (headerIndex === -1) return d;

        let asc = orders.order === "asc" ? 1 : -1;
        let desc = orders.order === "asc" ? -1 : 1;

        return [...d].sort((a, b) => {
            let a1 = a[headerIndex];
            let b1 = b[headerIndex];

            if (!a1 || !b1) return 0;

            if (Number(a1?.text) && Number(b1?.text)) {
                return Number(a1?.text) > Number(b1?.text) ? asc : desc;
            } else {
                return a1?.text > b1?.text ? asc : desc;
            };
        });
    };

    const deleteUser = (id) => {
        rpcClient({
            action: "call",
            method: "users.deleteUser",
            params: {
                id: id
            },
        }).then(() => {
            getUsers();
        })
    }


    React.useEffect(() => {
        getUsers();
    }, [filters])

    return <div className={`component__users`}>

        <div className="component__users__top">
            <p  onClick={(e) => animateBox(e, <CreateUserModal user={userDataSelector} onChange={getUsers} />)}>add User</p>
        </div>

        {data.status === "ok" && data.data.length && <FilteredCustomTable
            filterCB={c => setFilters(c)}
            id='table'
            accent='#000'
            dropdownBackground='#000'
            inputBottomBorderColor='#FFF'
            placeholderStyle={{ backgroundColor: '#000' }}
            theme='dark'
            filterHeadStyle={{ backgroundColor: 'rgba(25, 35, 67, 0.2)' }}
            tableHeadStyle={{ backgroundColor: 'rgba(25, 35, 67, 0.2)' }}
            buttonStyle={{ border: '1px solid #FFF', backgroundColor: '#000' }}
            customColumns={["auto", "auto", "auto", 'auto', '100px']}
            orderCB={o => setOrders(o)}
            headers={["ID", "Username", "Full Name", "Admin", "Manager"]}
            filters={[
                { name: "FirstName", friendlyName: "Name", type: "string" },
                { name: "Username", friendlyName: "Username", type: "string" },
                { name: "Email", friendlyName: "Email", type: "string" },
                { name: "updatedAt", friendlyName: "Date", type: "date" },
            ]}
            data={(() => {
                let out = [];

                if (!data) return [[{ keyID: "noData-spinner", type: "spinner" }]];
                if (data.status === "error") return [[{ keyID: "noData-error", type: "text", text: "Error while fetching data" }]];

                for (let item of data?.data) {
                    out.push([
                        { keyID: item.ID, type: "text", text: item.ID },
                        { keyID: item.ID, type: "text", text: item.Username },
                        { keyID: item.ID, type: "text", text: `${item.FirstName} ${item.LastName}` },
                        { keyID: item.ID, type: "text", text: item.Flags.isAdmin ? 'YES' : 'NO' },
                        { keyID: item.ID, type: "text", text: item.Flags.isManager ? 'YES' : 'NO' },
                        {
                            keyID: String(item.ID), type: "groupNewline", group: [
                                {
                                    keyID: String(item.ID), type: "button", text: "Delete", triggerDropdown: true, triggerData: e => {
                                        return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                                            <p style={{ color: 'white' }}>Are you sure?</p>
                                            <br></br>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Button style={{ display: spinner ? 'none' : 'block', marginRight: '10px' }} accent='rgb(72, 82, 92)' value='YES' onClick={() => deleteUser(item.ID)} />
                                                <Button style={{ display: spinner ? 'none' : 'block' }} accent='rgb(72, 82, 92)' value='NO' onClick={c => { e() }} />
                                                <div style={{ display: spinner ? 'block' : 'none' }}><Spinner color='white' width='32px' /></div>
                                            </div>
                                        </div>);
                                    },
                                },
                                {
                                    keyID: String(item.ID), type: "button", text: "Edit", triggerDropdown: true, onClick: e => {
                                        animateBox(e, <CreateUserModal edit={item} user={userDataSelector} onChange={getUsers} />)
                                    }
                                }
                            ]
                        }
                    ])
                };

                out = orderData(out, ["Timelapse", "Clicks", "SOI", "CR(SOI)", "DOI", "CR(DOI)", "Daily Users", "Avg. Active Time", "Unique Buyers", "Total Revenue"])
                if (spinner) out.push([{ keyID: "data-spinner", type: "spinner" }]);
                if (out.length === 0) out.push([{ keyID: "noData-noData", type: "text", text: "Nothing to show..." }]);

                return out;
            })()}
        />}

    </div>
};

const CreateUserModal = (props) => {

    const [flags, setFlags] = React.useState({});
    const [spinner, setSpinner] = React.useState(false)

    const [infoP, setInfoP] = React.useState();
    
    const usernameRef = React.useRef();
    const passwordRef = React.useRef();
    const firstNameRef = React.useRef();
    const lastNameRef = React.useRef();

    const onClose = (force = false) => {
        if (spinner && !force) return;

        props.onClose();
    };

    const addUser = () => {
        if(!usernameRef.current.value || !passwordRef.current.value) return;
        setSpinner(true);

        let data = {
            username: usernameRef.current.value,
            password: passwordRef.current.value,
            firstName: firstNameRef.current.value,
            lastName: lastNameRef.current.value,
            flags: flags
        }


        rpcClient({
            action: "call",
            method: "users.addUser",
            params: data
        }).then(res => {
            if(res.status === "ok"){
                console.log("user created successfully");
                props.onChange();
                props.onClose();
            }
            if(res.status === "error"){
                setInfoP(res.data)
            }
            setSpinner(false)
        })
    }


    const editUser = (user) => {
        setSpinner(true);

        let data = {
            id: user.ID,
            newUsername: usernameRef.current.value,
            newPassword: passwordRef.current.value,
            newFirstName: firstNameRef.current.value,
            newLastName: lastNameRef.current.value,
            newFlags: flags
        }

        rpcClient({
            action: "call",
            method: "users.editUser",
            params: data
        }).then(res => {
            if(res.status === "ok"){
                console.log("user edited successfully");
                props.onChange();
                props.onClose();
            }
            if(res.status === "error"){
                setInfoP(res.data)
            }
            setSpinner(false)
        })
    }


    console.log("props edit ", props.edit)


    return <div className="createUserModal" onClick={() => props.onClose()}>
        <div className="createUserModal__wrapper" onClick={(e) => e.stopPropagation()}>
            <p className="createUserModal__wrapper__headline">{props.edit ? 'Edit' : 'Add'} User</p>
            <p className="createUserModal__wrapper__label">Username</p>
            <input placeholder="Username" ref={usernameRef} defaultValue={props.edit?.Username}/>
            <p className="createUserModal__wrapper__label">Password</p>
            <input placeholder="Password" ref={passwordRef} />
            <p className="createUserModal__wrapper__label">First Name</p>
            <input placeholder="First Name" ref={firstNameRef} defaultValue={props.edit?.FirstName} />
            <p className="createUserModal__wrapper__label">Last Name</p>
            <input placeholder="Last Name" ref={lastNameRef} defaultValue={props.edit?.LastName} />
            <div className="createUserModal__wrapper__flags">
                <div className="createUserModal__wrapper__flags__checkbox">
                    <Checkbox defaultValue={props.edit?.Flags?.isAdmin ?? false} onChange={e => {
                        if (flags["isAdmin"] !== e) setFlags(flg => {
                            return { ...flg, isAdmin: e };
                        });
                    }} />
                    <span>Administrator</span>
                </div>
                <div className="createUserModal__wrapper__flags__checkbox">
                    <Checkbox defaultValue={props.edit?.Flags?.isManager ?? false} onChange={e => {
                        if (flags["isManager"] !== e) setFlags(flg => {
                            return { ...flg, isManager: e };
                        });
                    }} />
                    <span>Manager</span>
                </div>
            </div>
            <div className="createUserModal__wrapper__btns">
                <div className="createUserModal__wrapper__btns__btn" style={{backgroundColor: '#1B1D21'}} onClick={onClose}>
                    <p>Cancel</p>
                </div>
                {
                    props.edit ? <div className="createUserModal__wrapper__btns__btn" style={{backgroundColor: '#6C5DD3'}} onClick={() => {editUser(props.edit)}}>
                    {spinner ? <Spinner style={{ width: "24px", height: "24px" }} color="white" /> : <p>Edit</p>}
                    </div> : <div className="createUserModal__wrapper__btns__btn" style={{backgroundColor: '#6C5DD3'}} onClick={addUser}>
                        {spinner ? <Spinner style={{ width: "24px", height: "24px" }} color="white" /> : <p>Add</p>}
                    </div>
                }
                
            </div>
            <p className="createUserModal__wrapper__infoP" style={{
                opacity: infoP ? 1 : 0
            }}>{infoP}</p>
        </div>
    </div>
}

export default Users;