import "./index.scss";
import React from "react";

import axios, { all } from "axios";
import moment from "moment";
import rpcClient from "../../../modules/rpcClientModule";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "../../customComponents/Spinner";
import ChatBottom from "./ChatBottom";
import ChatContent from "./ChatContent";
import ChatHeader from "./ChatHeader";


const Chat = (props) => {

    const userInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});

    const [canUserChat, setCanUserChat] = React.useState();

    const getChatRoomByID = () => {
        if(!props.chatRoomID) return;

        rpcClient({
            action: "call",
            method: "chatRooms.getChatRoomByID",
            params: {
                ID: props?.chatRoomID
            },
        }).then(cr => {
            if(cr.status === 'ok'){
                let currUser = cr.data.Participants.find(el => el.ID === userInfoSelector.ID);
                if(cr.data.IsPrivateChat){
                    setCanUserChat(true)
                }else{
                    setCanUserChat(currUser.canChat);
                }
            }
        })
    };

    React.useEffect(() => {
        getChatRoomByID()
    }, [props.chatRoomID])


    return <div className="chatBody">
        <ChatHeader chatImage={props?.chatImage} chatName={props?.chatName} integrations={props?.integrations} participants={props?.participants} />
        <ChatContent chatRoomID={props.chatRoomID} />
        {canUserChat ? <ChatBottom onChange={props?.onChange} chatRoomID={props.chatRoomID} /> : null}
    </div>
}

export default Chat