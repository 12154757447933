const initialState = {
  notifications: [],
  chatRooms: {},
  teamChannels: [],
  tasks: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "NOTIFICATIONS_UPDATE": {
      return {
        ...state,
        chatRooms: action.payload.chatRooms || state.chatRooms,
        teamChannels: action.payload.teamChannels || state.teamChannels,
        tasks: action.payload.tasks || state.tasks,
        notifications: [
          ...state.notifications,
          ...(Array.isArray(action.payload.notifications) ? action.payload.notifications : [])
        ]
      };
    }

    case "ADD_NOTIFICATION":
      return {
        ...state,
        notifications: [...state.notifications, action.payload]
      };

    case "REMOVE_NOTIFICATION":
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.ID !== action.payload
        )
      };

    case 'REMOVE_ALL_NOTIFICATIONS':
      return {
        ...state,
        notifications: [],
      };

    case "MARK_NOTIFICATION_AS_READ":
      return {
        ...state,
        notifications: state.notifications.map(notification =>
          notification.id === action.payload
            ? { ...notification, status: 'read' }
            : notification
        )
      };

    case "ADD_TASK_NOTIFICATION":
      return {
        ...state,
        tasks: [...state.tasks, action.payload]
      };

    case "REMOVE_TASK_NOTIFICATION":
      return {
        ...state,
        tasks: state.tasks.filter(
          task => task.taskID !== action.payload
        )
      };

    case "ADD_CHATROOM_NOTIFICATION":
      const { isPrivate, ID } = action.payload;

      return {
        ...state,
        chatRooms: {
          ...state.chatRooms,
          isPrivateChat: isPrivate
            ? [...state?.chatRooms?.isPrivateChat, ID]
            : state?.chatRooms?.isPrivateChat,
          isTeamChat: !isPrivate
            ? [...state?.chatRooms?.isTeamChat, ID]
            : state?.chatRooms?.isTeamChat 
        }
      };
    case "REMOVE_CHATROOM_NOTIFICATION":
      return {
        ...state,
        chatRooms: {
          isPrivateChat: state.chatRooms.isPrivateChat.filter(
            chatRoomID => chatRoomID !== action.payload
          ),
          isTeamChat: state.chatRooms.isTeamChat.filter(
            chatRoomID => chatRoomID !== action.payload
          )
        }
      };

    case "REMOVE_TEAMCHANNEL_NOTIFICATION":
      return {
        ...state,
        teamChannels: state.teamChannels.filter(
          channel => channel.teamChannelID !== action.payload
        )
      };

    default:
      return state;
  }
};
