import "./index.scss";
import React from "react";

import rpcClient from "../../../modules/rpcClientModule";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { animateBox } from "../../../modules/componentAnimation";

import Spinner from "../../../components/customComponents/Spinner";

import MultiSelectUsers from "../MultiSelectUsersModal";
import languages from "../../../languages";


const CreateTeamChannelModal = (props) => {

    const languageSelector = useSelector(state => state?.siteFunctions?.language ?? 'en');

    const [spinner, setSpinner] = React.useState(false)
    const [infoP, setInfoP] = React.useState();
    const teamChannelNameRef = React.useRef();

    const [users, setUsers] = React.useState([])
    const [canChat, setCanChat] = React.useState(props.edit ? props.edit.ChatRights : 'Everyone');

    const [selectedUsers, setSelectedUsers] = React.useState(props.edit ? props.edit.Participants : []);

    const onClose = (force = false) => {
        if (spinner && !force) return;

        props.onClose();
    };

    const getUsers = () => {
        rpcClient({
            action: "call",
            method: "users.getAll",
            callback: d => setUsers(d)
        })
    };

    const addTeamChannel = () => {
        if (!teamChannelNameRef.current.value) return;
        setSpinner(true);

        let data = {
            Name: teamChannelNameRef.current.value,
            TeamID: props.teamID,
            Participants: selectedUsers,
            NotificationsFor: selectedUsers.map(el => el.ID),
            ChatRights: canChat
        }

        rpcClient({
            action: "call",
            method: "teamChannels.addTeamChannel",
            params: data
        }).then(res => {
            if (res.status === "ok") {
                console.log("team channel created successfully");
                props.onChange();
                props.onClose();
            }
            if (res.status === "error") {
                setInfoP(res.data)
            }
            setSpinner(false)
        })
    }


    const editTeamChannel = (team) => {
        setSpinner(true);

        let data = {
            ID: team.ID,
            OldName: team.Name,
            ChatRights: canChat,
            Name: teamChannelNameRef.current.value,
            Participants: selectedUsers
        }

        rpcClient({
            action: "call",
            method: "teamChannels.editTeamChannel",
            params: data
        }).then(res => {
            if (res.status === "ok") {
                console.log("team channel edited successfully");
                props.onChange();
                props.onClose();
            }
            if (res.status === "error") {
                setInfoP(res.data)
            }
            setSpinner(false)
        })
    }

    React.useEffect(() => {
        getUsers()
    }, []);


    return <div className="createTeamModal" onClick={() => props.onClose()}>
        <div className="createTeamModal__wrapper" onClick={(e) => e.stopPropagation()}>
            <p className="createTeamModal__wrapper__headline">{props.edit ? `${languages[languageSelector].edit}` : `${languages[languageSelector].add}`} {languages[languageSelector].teamChannel}</p>
            <p className="createTeamModal__wrapper__label">{languages[languageSelector].teamChannelName}</p>
            <input placeholder={`${languages[languageSelector].teamChannelName}`} ref={teamChannelNameRef} defaultValue={props.edit ? props.edit.Name : ''} />
            <p className="createTeamModal__wrapper__addTeamMember" onClick={(e) => animateBox(e,
                <MultiSelectUsers
                    headline="Select Users"
                    theme="dark"
                    accent="#fff"
                    selectBy="ID"
                    data={users.data}
                    selectedData={selectedUsers ? selectedUsers : []}
                    onChange={e => setSelectedUsers(e)}
                    style={{ marginBottom: '20px' }}
                />)}>{props.edit ? `${languages[languageSelector].edit}` : `${languages[languageSelector].add}`} {languages[languageSelector].teamChannelMembers}</p>
            <p className="createTeamModal__wrapper__label">{languages[languageSelector].whoHasRightToChat}</p>
            <div className="createTeamModal__wrapper__rightToChat">
                <p className={`${canChat === 'AdminOnly' ? 'right-to-chat-active' : ''} createTeamModal__wrapper__rightToChat__p`} onClick={e => setCanChat('AdminOnly')}>{languages[languageSelector].adminsOnly}</p>
                <p className={`${canChat === 'ManagementOnly' ? 'right-to-chat-active' : ''} createTeamModal__wrapper__rightToChat__p`} onClick={e => setCanChat('ManagementOnly')} >{languages[languageSelector].managementOnly}</p>
                <p className={`${canChat === 'Everyone' ? 'right-to-chat-active' : ''} createTeamModal__wrapper__rightToChat__p`} onClick={e => setCanChat('Everyone')} >{languages[languageSelector].everyone}</p>
            </div>
            <div className="createTeamModal__wrapper__btns">
                <div className="createTeamModal__wrapper__btns__btn" style={{ backgroundColor: '#1B1D21' }} onClick={onClose}>
                    <p>{languages[languageSelector].cancel}</p>
                </div>
                {
                    props.edit ? <div className="createTeamModal__wrapper__btns__btn" style={{ backgroundColor: '#6C5DD3' }} onClick={() => { editTeamChannel(props.edit) }}>
                        {spinner ? <Spinner style={{ width: "24px", height: "24px" }} color="white" /> : <p>{languages[languageSelector].edit}</p>}
                    </div> : <div className="createTeamModal__wrapper__btns__btn" style={{ backgroundColor: '#6C5DD3' }} onClick={addTeamChannel}>
                        {spinner ? <Spinner style={{ width: "24px", height: "24px" }} color="white" /> : <p>{languages[languageSelector].add}</p>}
                    </div>
                }
            </div>
            <p className="createTeamModal__wrapper__infoP" style={{
                opacity: infoP ? 1 : 0
            }}>{infoP}</p>
        </div>
    </div>
}

export default CreateTeamChannelModal