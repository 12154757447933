import "./index.scss";
import React from "react";

import axios from "axios";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import rpcClient from "../../modules/rpcClientModule";
import Spinner from "../../components/customComponents/Spinner";
import { animateBox } from "../../modules/componentAnimation"
import ChatWrapper from "../../components/integrations/Chat";

import * as notificationsActions from "../../actions/notificationsActions.js";

import languages from "../../languages.js";

import animateModule from "../../modules/animateModule";

const Chat = (props) => {

  const languageSelector = useSelector(state => state?.siteFunctions?.language ?? 'en');

  const curDispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const chatIDFromURL = queryParams.get('ID');

  const [privateChats, setPrivateChats] = React.useState([]);
  const [openedPrivateChatID, setOpenedPrivateChatID] = React.useState('');
  const [openedPrivateChat, setOpenedPrivateChat] = React.useState();
  const [users, setUsers] = React.useState();
  const [chatUserImage, setChatUserImage] = React.useState();

  const userDataSelector = useSelector(state => state?.userData ?? {});
  const notificationsSelector = useSelector(state => state?.notifications ?? {});

  const [filters, setFilters] = React.useState([]);

  const getAllPrivateChats = () => {
    rpcClient({
      action: "call",
      method: "chatRooms.getAllBasic",
      params: {
        filters: filters.length ? filters : []
      },
    }).then(privateChats => {
      if (privateChats.status === 'ok') {
        setPrivateChats(privateChats)
      } else {
        setPrivateChats({ status: 'error', data: [] })
      }

    })
  };

  const getUsers = () => {
    rpcClient({
      action: "call",
      method: "users.getAll",
      callback: d => setUsers(d)
    })
  };

  const getPrivateChatByID = () => {
    if (!openedPrivateChatID) return;
    rpcClient({
      action: "call",
      method: "chatRooms.getChatRoomByID",
      params: {
        ID: openedPrivateChatID,
      }
    }).then(res => {
      if(res.status === 'ok'){
        setOpenedPrivateChat(res.data)
      }
    })
  }

  const formatDisplayName = (fullDisplayName, yourRole) => {
    const namesArray = fullDisplayName.split(' - ');
    const filteredNames = namesArray.filter(name => name !== yourRole);
    return filteredNames.join(' - ');
  }

  const removeFromNotifications = (id) => {
    rpcClient({
      action: "call",
      method: "chatRooms.removeFromNotifications",
      params: {
        ID: id
      }
    })
  };


  React.useEffect(() => {
    getAllPrivateChats();
    getUsers();
  }, []);

  React.useEffect(() => {
    getPrivateChatByID();
  }, [openedPrivateChatID])


  // Check if chatIDFromURL is present and open that chat
  React.useEffect(() => {
    if (chatIDFromURL) {
      setOpenedPrivateChatID(chatIDFromURL);
      curDispatch(notificationsActions.removeChatRoomNotification(chatIDFromURL));
      removeFromNotifications(chatIDFromURL);
    }
  }, [chatIDFromURL]);


  return <div className={`component__chats`}>

    <div className="component__innerNavbar">
      <div className="component__innerNavbar__head">
        <p className="component__innerNavbar__head__header">{languages[languageSelector].chats}</p>
        <p className="component__innerNavbar__headAdd" onClick={(e) => animateBox(e, <CreatePrivateChat user={userDataSelector} onChange={getAllPrivateChats} setOpenedPrivateChatID={setOpenedPrivateChatID} />)}><img src="./images/plusIcon.svg" /></p>
      </div>

      {privateChats?.status === 'ok' ? privateChats?.data.map(el => {

        let notificationExists = notificationsSelector?.chatRooms?.isPrivateChat?.find(elem => elem === el.ID)

        const currUserID = userDataSelector?.userData?.UserInfo?.ID;

        let chatName = '';
        let imageSRC = '';

        if(el?.Participants?.length > 2){
          imageSRC = `${el.Image ? el.Image : './images/logo.svg'}`;
          chatName = formatDisplayName(el.Name, `${userDataSelector?.userData?.UserInfo?.FirstName}`)
        }else{
          let otherUserParticipant = el.Participants.find(participant => participant?.ID !== currUserID);
          let otherUser = users?.data?.find(user => user.ID === otherUserParticipant?.ID);
          imageSRC = otherUser?.Image;
          chatName = `${otherUser?.FirstName} ${otherUser?.LastName}`;
        }

        if (!imageSRC) imageSRC = './images/logo.svg';
        if (!chatName) chatName = 'ChatRoom';

        const formattedDate = moment(el.updatedAt).format('DD/MM/YYYY');

        return (
          <div className={`component__innerNavbar__item ${openedPrivateChatID === el.ID ? 'component__innerNavbar__item__opened' : ''}`}
            onClick={() => { 
              setOpenedPrivateChatID(el.ID); 
              curDispatch(notificationsActions.removeChatRoomNotification(el.ID));
              removeFromNotifications(el.ID);
              setChatUserImage(imageSRC)
            }}>
            {notificationExists && <div className="component__innerNavbar__item--dot"></div>}
            <div className="component__innerNavbar__item__top">
              <div className="component__innerNavbar__item__top__left">
                <img src={`${imageSRC}`} />
              </div>
              <div className="component__innerNavbar__item__top__right">
                <div className="component__innerNavbar__item__top__right__top">
                  <p className="component__innerNavbar__item__top__right__top__name">{chatName} {el.Participants.length > 2 ? `(${el.Participants.length})` : ''}</p>
                  <p className="component__innerNavbar__item__top__right__top__date">{formattedDate}</p>
                </div>
                <div className="component__innerNavbar__item__top__right__bot">
                  {el?._lastChat && <p className="component__innerNavbar__item__top__right__bot__msg">{el?._lastChat?.Type === 'text' ? el._lastChat.Content : '[Attachment]'}</p>}
                  <img src="./images/pinIcon.svg" />
                </div>
              </div>
            </div>
          </div>
        );
      }) : <Spinner color="#fff" style={{marginLeft: 'auto', marginRight: 'auto'}}/>}
    </div>

    {openedPrivateChat && (() => {
      const yourFullName = `${userDataSelector?.userData?.UserInfo?.FirstName} ${userDataSelector?.userData?.UserInfo?.LastName}`;

      const chatImage = chatUserImage || 
        openedPrivateChat.Participants.find(participant => 
          `${participant.FirstName} ${participant.LastName}` !== yourFullName)?.Image || 
        "./images/logo.svg";

      let chatName = '';
      openedPrivateChat.Participants?.length > 2 ? 
      chatName = formatDisplayName(openedPrivateChat.Name, `${userDataSelector?.userData?.UserInfo?.FirstName}`) : 
      chatName = formatDisplayName(openedPrivateChat.Name, `${userDataSelector?.userData?.UserInfo?.FirstName} ${userDataSelector?.userData?.UserInfo?.LastName}`)

      return (
        <ChatWrapper 
          key={openedPrivateChatID}
          participants={openedPrivateChat?.Participants}
          chatName={chatName}
          chatImage={chatImage}
          chatRoomID={openedPrivateChatID} 
          onChange={getAllPrivateChats}
        />
      );
    })()}

  </div>
};

const CreatePrivateChat = (props) => {
  const languageSelector = useSelector(state => state?.siteFunctions?.language ?? 'en');
  const [spinner, setSpinner] = React.useState(false)
  const [infoP, setInfoP] = React.useState();
  const chatNameRef = React.useRef();

  const [users, setUsers] = React.useState([])

  const [selectedUsers, setSelectedUsers] = React.useState(props.edit ? props.edit.Participants : []);

  //get all users, set selected users

  const onClose = (force = false) => {
    if (spinner && !force) return;

    props.onClose();
  };

  const getUsers = () => {
    rpcClient({
      action: "call",
      method: "users.getAll",
      callback: d => setUsers(d)
    })
  };

  const createChatRoom = () => {

    if (!chatNameRef.current.value) {
      let name = '';
      if (selectedUsers.length === 2) {
        selectedUsers.forEach((item, index) => {
          if (index === selectedUsers.length - 1) {
            name = name + `${item.FirstName} ${item.LastName}`
          } else {
            name = name + `${item.FirstName} ${item.LastName} - `
          }
        })
      } else {
        selectedUsers.forEach((item, index) => {
          if (index === selectedUsers.length - 1) {
            name = name + `${item.FirstName}`
          } else {
            name = name + `${item.FirstName} - `
          }
        })
      }
      chatNameRef.current.value = name
    }
    setSpinner(true);

    let data = {
      Name: chatNameRef.current.value,
      Participants: selectedUsers,
      IsPrivateChat: true
    }

    rpcClient({
      action: "call",
      method: "chatRooms.createChatRoom",
      params: data
    }).then(res => {
      if (res.status === "ok") {
        if(res?.data?.data?.exists){
          props.setOpenedPrivateChatID(res.data.data.ID)
        }
        props.onChange();
        props.onClose();
      }
      if (res.status === "error") {
        setInfoP(res.data)
      }
      setSpinner(false)
    })
  }


  const editChatRoom = (team) => {
    setSpinner(true);

    let data = {
      ID: team.ID,
      Name: chatNameRef.current.value,
      Participants: selectedUsers
    }

    rpcClient({
      action: "call",
      method: "chatRooms.editChatRoom",
      params: data
    }).then(res => {
      if (res.status === "ok") {
        props.onChange();
        props.onClose();
      }
      if (res.status === "error") {
        setInfoP(res.data)
      }
      setSpinner(false)
    })
  }

  React.useEffect(() => {
    getUsers()
  }, [])


  return <div className="createTeamModal" onClick={() => props.onClose()}>
    <div className="createTeamModal__wrapper" onClick={(e) => e.stopPropagation()}>
      <p className="createTeamModal__wrapper__headline">{props.edit ? `${languages[languageSelector].editChat}` : `${languages[languageSelector].startChat}`}</p>
      <p className="createTeamModal__wrapper__label">{languages[languageSelector].chatName}</p>
      <input placeholder={`${languages[languageSelector].chatName}`} ref={chatNameRef} defaultValue={props.edit ? props.edit.Name : ''} />
      <p className="createTeamModal__wrapper__addTeamMember" onClick={(e) => animateBox(e,
        <MultiSelectUsers
          headline={`${languages[languageSelector].selectUsers}`}
          theme="dark"
          accent="#fff"
          selectBy="ID"
          data={users.data}
          selectedData={selectedUsers ? selectedUsers : []}
          onChange={e => setSelectedUsers(e)}
          style={{ marginBottom: '20px' }}
        />)}>{languages[languageSelector].addChatMembers}</p>
      <div className="createTeamModal__wrapper__btns">
        <div className="createTeamModal__wrapper__btns__btn" style={{ backgroundColor: '#1B1D21' }} onClick={onClose}>
          <p>{languages[languageSelector].cancel}</p>
        </div>
        {
          props.edit ? <div className="createTeamModal__wrapper__btns__btn" style={{ backgroundColor: '#6C5DD3' }} onClick={() => { editChatRoom(props.edit) }}>
            {spinner ? <Spinner style={{ width: "24px", height: "24px" }} color="white" /> : <p>{languages[languageSelector].edit}</p>}
          </div> : <div className="createTeamModal__wrapper__btns__btn" style={{ backgroundColor: '#6C5DD3' }} onClick={createChatRoom}>
            {spinner ? <Spinner style={{ width: "24px", height: "24px" }} color="white" /> : <p>{languages[languageSelector].add}</p>}
          </div>
        }

      </div>
      <p className="createTeamModal__wrapper__infoP" style={{
        opacity: infoP ? 1 : 0
      }}>{infoP}</p>
    </div>
  </div>
}


const MultiSelectUsers = props => {

  const languageSelector = useSelector(state => state?.siteFunctions?.language ?? 'en');
  const userDataSelector = useSelector(state => state?.userData ?? {});

  const [selectedData, setSelectedData] = React.useState(props.selectedData ? [...props.selectedData, userDataSelector?.userData?.UserInfo] : [userDataSelector?.userData?.UserInfo]);
  const [hoveredDataElem, setHoveredDataElem] = React.useState(null);
  const [hoveredDataSuggestedElem, setHoveredDataSuggestedElem] = React.useState(null);
  const [data, setData] = React.useState(props.data ? props.data : []);
  const [suggestedData, setSuggestedData] = React.useState(props.suggestedData ? props.suggestedData : []);
  const [selectedRole, setSelectedRole] = React.useState('');
  const [filteredData, setFilteredData] = React.useState(props.data ? props.data : [])

  const searchRef = React.useRef();
  const searchTimeoutRef = React.useRef(null);

  const addSelectedData = (item) => {
    let foundElem;
    foundElem = selectedData.find(elem => elem.ID === item.ID);

    if (!foundElem) {
      setSelectedData([...selectedData, item]);
    } else {
      setSelectedData(selectedData.filter(obj => obj.ID !== item.ID));
    }
  }

  const checkIfItemIsSelected = (id) => {
    let checker = selectedData.find(el => el.ID === id)
    if (checker) {
      return 'data--item--selected'
    }
  }

  const changeRoleFilter = (gender) => {
    if (!selectedRole) setSelectedRole(gender);
    if (selectedRole === gender) setSelectedRole('');
    if (selectedRole && selectedRole !== gender) setSelectedRole(gender)
  }

  const performSearch = () => {
    const searchValue = searchRef?.current?.value.toLocaleLowerCase();

    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    searchTimeoutRef.current = setTimeout(() => {
      if (searchValue) {
        const updatedData = data.filter((elem) => {
          const searchTarget = `${elem.Username} ${elem.FirstName} ${elem.LastName}`.toLocaleLowerCase();
          return searchTarget.includes(searchValue);
        });
        setFilteredData(updatedData);
      } else {
        setFilteredData(data);
      }
    }, 300);
  }


  return <div className="route__usersList__multiselect" >
    <div className="route__usersList__multiselect__inner" style={{ ...(props.style ?? {}), ...(props.popupStyle ?? {}) }}>

      <div className="route__usersList__multiselect__inner__genderBtns">
        <p onClick={() => changeRoleFilter('isAdmin')} style={{ borderColor: selectedRole === 'isAdmin' ? '#38ef7d' : 'white', color: selectedRole === 'isAdmin' ? '#38ef7d' : 'white' }}>{languages[languageSelector].admins}</p>
        <p onClick={() => changeRoleFilter('isManager')} style={{ borderColor: selectedRole === 'isManager' ? '#38ef7d' : 'white', color: selectedRole === 'isManager' ? '#38ef7d' : 'white' }}>{languages[languageSelector].managers}</p>
        <div class="form__group field">
          <input type="input" class="form__field" ref={searchRef} onChange={performSearch} placeholder={`${languages[languageSelector].name}`} />
          <label for="name" class="form__label">{languages[languageSelector].name}</label>
        </div>
      </div>

      {suggestedData && suggestedData.length ? <p className="route__usersList__multiselect__inner__suggestedHeadline"
        style={{ color: props.accent ? props.accent : '#fff' }}
      >Suggested:</p> : null}
      {suggestedData && suggestedData.length ? <div className="route__usersList__multiselect__inner__data"
        style={{ marginBottom: '20px' }}>
        {suggestedData.map((elem, index) => {
          const isHovered = index === hoveredDataSuggestedElem;

          return <div className={''}
            style={{
              color: props.accent ? props.accent : '#fff',
              border: `1px solid ${props.accent}`,
              boxShadow: isHovered ? `inset 0 0 8px 2px ${props.accent ? props.accent : null}` : 'none',
            }}
            onMouseEnter={(e) => { setHoveredDataSuggestedElem(index) }}
            onMouseLeave={(e) => setHoveredDataSuggestedElem(null)}
            onClick={(e) => { addSelectedData(elem); e.currentTarget.classList.contains("data--item--selected") ? e.currentTarget.classList.remove("data--item--selected") : e.currentTarget.classList.add("data--item--selected") }}
          >
            <p>{elem.name} </p>
          </div>
        })}
      </div> : null}

      <div className="route__usersList__multiselect__inner__data">
        {filteredData.map((elem, index) => {
          if (selectedRole && !elem.Flags[`${selectedRole}`]) return;
          const isHovered = index === hoveredDataElem;
          return <div className={`${checkIfItemIsSelected(elem.ID)}`}
            style={{
              color: props.accent ? props.accent : '#fff',
              border: `1px solid ${props.accent}`,
              boxShadow: isHovered ? `inset 0 0 8px 2px ${props.accent ? props.accent : null}` : 'none',
            }}
            onMouseEnter={(e) => { setHoveredDataElem(index) }}
            onMouseLeave={(e) => setHoveredDataElem(null)}
            onClick={(e) => { addSelectedData(elem); e.currentTarget.classList.contains("data--item--selected") ? e.currentTarget.classList.remove("data--item--selected") : e.currentTarget.classList.add("data--item--selected") }}
          >
            {elem.Username === 'rootAdmin' ? <p>{elem.Username}</p> : <p>{elem.FirstName} {elem.LastName}</p>}
          </div>
        })}
      </div>

      <div className="route__usersList__createComment__wrap__btns">
        <p onClick={() => { props.onChange(selectedData); props.onClose() }}>
          {/* <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" /> */}
          <span style={{ color: 'rgb(64, 149, 255)' }}>{languages[languageSelector].select}</span>
        </p>
        <p onClick={props.onClose}>
          <span style={{ color: '#EA3F3F' }}>{languages[languageSelector].cancel}</span>
          {/* <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" /> */}
        </p>
      </div>

    </div>
  </div>
}


export default Chat;