import React from "react";
import "./index.scss";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import animateModule from "../../modules/animateModule";

import * as userDataActions from "../../actions/userDataActions";
import * as timestmapActions from "../../actions/timestampActions";

import Spinner from "../../components/customComponents/Spinner";

import rpcClient from "../../modules/rpcClientModule";

let loginTimeout = null;
const Login = () => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState({
        hadError: false,
        inputs: [],
        error: "-"
    });

    const usernameRef = React.useRef();
    const passwordRef = React.useRef();

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            checkLogin();
        }
    };

    const curNavigate = useNavigate();
    const curDispatch = useDispatch();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const checkLogin = () => {
        setInfoP(ip => { return { ...ip, hadError: false, inputs: [] } });

        let data = {
            username: usernameRef.current.value,
            password: passwordRef.current.value
        };

        if (!data.username) {
            return setInfoP(ip => { return { ...ip, hadError: true, inputs: ["username"], error: "Username can't be empty." } });
        };
        if (!data.password) {
            return setInfoP(ip => { return { ...ip, hadError: true, inputs: ["password"], error: "Password can't be empty." } });
        };

        setSpinner(true);

        rpcClient({
            action: "call",
            method: "auth.login",
            params: data
        }).then(res => {

            if (res.status === "error" && res.data === "RPC_CLIENT_ERROR") {
                curDispatch(userDataActions.setUserData(null, null));
                setTimeout(() => curDispatch(timestmapActions.updateTimestamp()), 2000);

                loginTimeout = setTimeout(checkLogin, 2000);
            }

            if (res.status === "ok") {
                curDispatch(timestmapActions.updateTimestamp());
                animateNavigate("/");
            } else {
                setSpinner(false)
                return setInfoP(ip => {return {...ip, hadError: true, inputs: ["username", "password"], error: "Invalid username or password!"}});
            }
        })
    };

    React.useEffect(() => {
        const body = document.querySelector('.component__login');

        body.scrollIntoView({
            behavior: 'instant'
        })

    }, []);

    return <div className="component__login">

        <div className="component__login__container">
            <div className="component__login__container__logo" style={{ backgroundImage: 'url("./images/logo.svg")' }}></div>
            <p className="component__login__container__heading">Sign in</p>

            <div className={`component__login__container__input ${infoP.inputs.includes("username") ? "component__login__container__input--error" : ""}`}>
                <input ref={usernameRef} type="text" placeholder="Username" />
            </div>
            <div className={`component__login__container__input ${infoP.inputs.includes("password") ? "component__login__container__input--error" : ""}`}>
                <input ref={passwordRef} type="password" placeholder="Password" onKeyDown={handleKeyDown} />
            </div>

            <div className="component__login__container__button"
                style={{
                    pointerEvents: spinner ? "none" : "all"
                }}
                onClick={checkLogin}
            >
                {spinner ? <Spinner style={{ width: "48px", height: "48px" }} color="white" /> : "Sign in to ScaleTeams"}
            </div>

            <p className="component__login__container__infoP" style={{
                opacity: infoP.hadError ? 1 : 0
            }}>{infoP.error}</p>
        </div>
    </div>
};

export default Login;