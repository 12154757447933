import "./index.scss";
import React from "react";

import rpcClient from "../../modules/rpcClientModule";

import TaskSystem from "../../components/integrations/TaskSystem";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

import languages from "../../languages";


const Tasks = (props) => {
  const notificationsSelector = useSelector(state => state?.notifications ?? {});
  const languageSelector = useSelector(state => state?.siteFunctions?.language ?? 'en');

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [windowSize, setWindowSize] = React.useState(window.innerWidth);
  const [filters, setFilters] = React.useState([]);
  const [teams, setTeams] = React.useState([]);
  const [openedTeamID, setOpenedTeamID] = React.useState('');
  const [openedTeamChannel, setOpenedTeamChannel] = React.useState();
  const [teamChannels, setTeamChannels] = React.useState();

  const teamChannelIDFromURL = queryParams.get('teamChannelID');

  const getAllTeams = () => {
    rpcClient({
      action: "call",
      method: "teams.getAll",
      params: {
        filters: filters.length ? filters : [],
        orders: [{ name: "updatedAt", order: "desc" }],
      },
      callback: d => setTeams(d.data)
    });
  };

  const getTeamChannels = (teamID) => {
    rpcClient({
      action: "call",
      method: "teamChannels.getAll",
      params: {
        filters: [
          { name: "TeamID", op: "eq", value: teamID }
        ],
        orders: [{ name: "createdAt", order: "asc" }],
      },
      callback: d => setTeamChannels(d.data)
    }).then((teamChannels) => {
      if (teamChannels.status !== 'ok') return;
      
      if (teamChannelIDFromURL) {
        const defaultChannel = teamChannels.data.find(channel => channel.ID === teamChannelIDFromURL);
        if (defaultChannel) {
          setOpenedTeamChannel(defaultChannel);
        }
      } else {
        setOpenedTeamChannel(teamChannels.data[0]);
      }
    });
  };

  const openTeamAndChannelByURL = () => {
    if (!teamChannelIDFromURL) return;

    rpcClient({
      action: "call",
      method: "teamChannels.getTeamChannelByID",
      params: {
        ID: teamChannelIDFromURL
      },
      callback: d => {
        if (d.status === 'ok') {
          const teamChannel = d.data;
          setOpenedTeamID(teamChannel.TeamID);
          setOpenedTeamChannel(teamChannel);
        }
      }
    });
  };

  React.useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  React.useEffect(() => {
    getAllTeams();
  }, []);

  React.useEffect(() => {
    if (openedTeamID) {
      getTeamChannels(openedTeamID);
    }
  }, [openedTeamID]);

  React.useEffect(() => {
    openTeamAndChannelByURL();
  }, []);

  return (
    <div className={`component__tasks`}>
      <div className="component__innerNavbar">
        <div className="component__innerNavbar__head">
          <p className="component__innerNavbar__head__header">{languages[languageSelector].tasks}</p>
        </div>
        {teams.length && teams.map(el => {
          const hasNotifications = el.channels.some(elem =>
            notificationsSelector.tasks.some(item => elem.ID === item.teamChannelID)
          );
          return (
            <div
              key={el.ID}
              className={`component__innerNavbar__item ${openedTeamID === el.ID ? 'component__innerNavbar__item__opened' : ''}`}
              onClick={() => { setOpenedTeamID(el.ID); }}
            >
              {hasNotifications && (<div className="component__innerNavbar__item--dot"></div>)}
              <div className="component__innerNavbar__item__top">
                <div className="component__innerNavbar__item__top__left">
                  <img src="./images/logo.svg" alt="team-logo"/>
                </div>
                <div className="component__innerNavbar__item__top__right">
                  <p className="component__innerNavbar__item__top__right__name">{el.Name} ({el.Participants.length})</p>
                </div>
              </div>
              <div className={`component__innerNavbar__item__bottom ${openedTeamID === el.ID ? 'component__innerNavbar__item__bottom__active' : ''}`}>
                {el.channels.map(elem => {
                  const hasChannelNotification = notificationsSelector.tasks.some(item => elem.ID === item.teamChannelID);
                  return (
                    <div
                      key={elem.ID}
                      className={`component__innerNavbar__item__bottom__item ${openedTeamChannel?.ID === elem.ID ? 'component__innerNavbar__item__bottom__item__active' : ''}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (openedTeamChannel?.ID !== elem.ID) {
                          setOpenedTeamChannel(elem);
                        }
                      }}
                    >
                      {elem.Name}
                      {hasChannelNotification && (<div className="component__innerNavbar__item__bottom__item--dot"></div>)}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <div className="component__tasks__list">
        {openedTeamChannel && <TaskSystem teamChannel={openedTeamChannel}/>}
      </div>
    </div>
  );
};


export default Tasks;