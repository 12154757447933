import React from "react";
import "./scss/globals.scss";
import { HashRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";

import animateModule from "./modules/animateModule";
import * as backendModule from "./modules/backendModule";

import * as userDataActions from "./actions/userDataActions";
import * as notificationsActions from "./actions/notificationsActions";
import * as timestmapActions from "./actions/timestampActions";

import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Spinner from "./components/customComponents/Spinner";
import ContentWrapper from "./components/ContentWrapper";

import LandingPage from "./routes/LandingPage";
import HomePage from "./routes/HomePage";
import Teams from "./routes/Teams";
import Tasks from "./routes/Tasks";
import AdminTeams from "./routes/Admin/Teams";
import AdminTasks from "./routes/Admin/Tasks";
import Users from "./routes/Admin/Users";
import Chat from "./routes/Chat";
import Login from "./routes/Login";

import rpcClient from "./modules/rpcClientModule"
import Notifications from "./components/Notifications";

let loginTimeout = null;
const App = () => {

  const mainDispatch = useDispatch();
  const mainNavigate = useNavigate();

  const userDataSelector = useSelector(state => state?.userData ?? {});
  const notificationsSelector = useSelector(state => state?.notifications ?? {});
  const timestampSelector = useSelector(state => state?.timestamp ?? null);
  const stickySidebarSelector = useSelector(state => state?.siteFunctions?.stickySidebar ?? false);

  const animateNavigate = to => {
    animateModule(mainNavigate, to, document.querySelector(".component__contentWrapper"));
  };

  const checkLogin = () => {
    rpcClient({
      action: "call",
      method: "auth.checkLogin"
    }).then(res => {

      if (res.status === "error" && res.data === "RPC_CLIENT_ERROR") {
        mainDispatch(userDataActions.setUserData(null, null));

        loginTimeout = setTimeout(checkLogin, 2000);
        return
      }

      if (res.status === "ok") {

        mainDispatch(userDataActions.setUserData(true, res.data));
        if (!userDataSelector?.isLoggedIn) {
          if (window.location.toString().endsWith("/login")) animateNavigate("/");
        };

        loginTimeout = setTimeout(checkLogin, 60000);
      } else {
        if (!userDataSelector?.isLoggedIn) {
          if (!window.location.toString().endsWith("/login")) animateNavigate("/login");
        };
        mainDispatch(userDataActions.setUserData(false, null));
      };
    }).catch(() => {
      mainDispatch(userDataActions.setUserData(null, null));

      loginTimeout = setTimeout(checkLogin, 2000);
    })
  };

  const getInitialNotifications = () => {
    if (!userDataSelector.isLoggedIn) return;
    rpcClient({
      action: "call",
      method: "users.getAllNotificationsFor"
    }).then(res => {
      if (res.status === 'ok') {
        mainDispatch(notificationsActions.setNotifications(
          res.data.notifications || [],
          res.data.chatRooms,
          res.data.teamChannels,
          res.data.tasks
        ));
      }
    })
  }

  const unsubscribeRef = React.useRef();

  const subscribeToNotifications = () => {

    if (!userDataSelector.isLoggedIn) return;

    rpcClient({
      action: "subscribe",
      method: "notifications.subscribe",
      subscribeDataCallback: (d, item) => {
        mainDispatch(notificationsActions.addNotification(d))
        mainDispatch(notificationsActions.addChatRoomNotification(d.AdditionalData))
      },
      subscribeOpenedCallback: unsub => { unsubscribeRef.current = unsub }
    }).then(res => {

    })
  }

  React.useEffect(() => {
    checkLogin();

    return () => clearTimeout(loginTimeout);
  }, [timestampSelector]);

  React.useEffect(() => {
    getInitialNotifications()
  }, [userDataSelector?.isLoggedIn])

  React.useEffect(() => {
    subscribeToNotifications();

    return () => {
      if (typeof (unsubscribeRef.current) === 'function') {
        unsubscribeRef.current();
      }
    };
  }, [userDataSelector?.isLoggedIn]);


  return (
    <>
      {userDataSelector.isLoggedIn === null ? (
        <div className="root__init">
          <Spinner color="#FCA311" />
        </div>
      ) : (
        userDataSelector.isLoggedIn ? (
          <React.Fragment key="logged-in-fragment">
            <Sidebar />
            <Notifications />
            <ContentWrapper
              key="wrapper-loggedIn"
              style={{
                display: "grid",
                gridTemplateRows: "1fr",
                gridTemplateColumns: "1fr",
                rowGap: "20px",
                paddingLeft: '84px',
              }}
            >
              <div className="root__content" style={{ paddingLeft: stickySidebarSelector ? '338px' : '80px', transition: 'padding-left 0.3s ease' }}>
                <Routes>
                  <Route path="/" element={<Teams />} />
                  <Route path="/teams" element={<Teams />} />
                  <Route path="/chat" element={<Chat />} />
                  <Route path="/Tasks" element={<Tasks />} />

                  {/* TODO SAMO ADMIN */}
                  <Route path="/admin-users" element={<Users />} />
                  <Route path="/admin-teams" element={<AdminTeams />} />
                  <Route path="/admin-tasks" element={<AdminTasks />} />
                </Routes>
              </div>
            </ContentWrapper>
          </React.Fragment>
        ) : (
          <ContentWrapper
            style={{ gridRow: "1 / span 2", gridColumn: "1 / span 2" }}
          >
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<LandingPage />} />
            </Routes>
          </ContentWrapper>
        )
      )}
    </>
  );
};

export default App;
