export const setNotifications = (notifications = [], chatRooms = {}, teamChannels = [], tasks = []) => {
  return {
    type: "NOTIFICATIONS_UPDATE",
    payload: {
      notifications,
      chatRooms,
      teamChannels,
      tasks
    }
  };
};

export const addNotification = (notification) => {
  return {
    type: "ADD_NOTIFICATION",
    payload: notification
  };
};

export const removeNotification = (id) => {
  return {
    type: "REMOVE_NOTIFICATION",
    payload: id
  };
};

export const removeAllNotifications = () => {
  return {
    type: 'REMOVE_ALL_NOTIFICATIONS'
  };
};

export const markNotificationAsRead = (id) => {
  return {
    type: "MARK_NOTIFICATION_AS_READ",
    payload: id
  };
};

export const addTaskNotification = (notification) => {
  return {
    type: "ADD_TASK_NOTIFICATION",
    payload: notification
  };
};

export const removeTaskNotification = (id) => {
  return {
    type: "REMOVE_TASK_NOTIFICATION",
    payload: id
  };
};

export const addChatRoomNotification = (id) => {
  return {
    type: "ADD_CHATROOM_NOTIFICATION",
    payload: id
  };
};
export const removeChatRoomNotification = (id) => {
  return {
    type: "REMOVE_CHATROOM_NOTIFICATION",
    payload: id
  };
};

export const removeTeamChannelNotification = (id) => {
  return {
    type: "REMOVE_TEAMCHANNEL_NOTIFICATION",
    payload: id
  };
};