import "./index.scss";
import React from "react";

import rpcClient from "../../../modules/rpcClientModule";

import TaskSystem from "../../../components/integrations/TaskSystem";


const AdminTasks = (props) => {

    const [windowSize, setWindowSize] = React.useState(window.innerWidth);

    const [filters, setFilters] = React.useState([]);

    const [teams, setTeams] = React.useState([]);
    const [openedTeamID, setOpenedTeamID] = React.useState('');
    const [openedTeam, setOpenedTeam] = React.useState();
    const [openedTeamChannel, setOpenedTeamChannel] = React.useState();
    const [openedTeamChannelTab, setOpenedTeamChannelTab] = React.useState('General');

    const [teamChannels, setTeamChannels] = React.useState();

    const getAllTeams = () => {
        rpcClient({
            action: "call",
            method: "teams.getAll",
            params: {
                filters: filters.length ? filters : [],
                orders: [{name: "updatedAt", order: "desc"}],
                allTeams: true,
            },
            callback: d => setTeams(d.data)
        })
    };

    const getTeamByID = () => {
        if (!openedTeamID) return;
        rpcClient({
            action: "call",
            method: "teams.getTeam",
            params: {
                ID: openedTeamID,
            },
            callback: d => setOpenedTeam(d.data)
        })
    }

    const getTeamChannels = () => {
        if (!openedTeamID) return;
        rpcClient({
            action: "call",
            method: "teamChannels.getAll",
            params: {
                filters: [
                    { name: "TeamID", op: "eq", value: openedTeamID }
                ],
                orders: [{ name: "createdAt", order: "asc" }],
                allTeamChannels: true
            },
            callback: d => setTeamChannels(d.data)
        }).then((teamChannel) => {
            if (teamChannel.status !== 'ok') return;
            let openedTeamChannel = teamChannel.data.find(el => el.Name === openedTeamChannelTab);
            
            if (openedTeamChannel) {
                setOpenedTeamChannel(openedTeamChannel)
            } else {
                setOpenedTeamChannelTab('General')
            }
        })
    };

    const changeTeamChannel = () => {
        if (!teamChannels) return
        setOpenedTeamChannel(teamChannels?.data?.find(el => el?.Name === openedTeamChannelTab))
    }

    React.useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    });

    React.useEffect(() => {
        getAllTeams();
    }, []);

    React.useEffect(() => {
        getTeamByID();
        getTeamChannels();
    }, [openedTeamID])

    React.useEffect(() => {
        changeTeamChannel();
    }, [openedTeamChannelTab])


    return <div className={`component__tasks`}>
        <div className="component__innerNavbar">
            <div className="component__innerNavbar__head">
                <p className="component__innerNavbar__head__header">Tasks</p>
            </div>
            {teams.length && teams.map(el => {
                return (
                    <div
                        className={`component__innerNavbar__item ${openedTeamID === el.ID ? 'component__innerNavbar__item__opened' : ''}`}
                        onClick={() => { setOpenedTeamID(el.ID)}}
                    >
                        <div className="component__innerNavbar__item__top">
                            <div className="component__innerNavbar__item__top__left">
                                <img src="./images/logo.svg" />
                            </div>
                            <div className="component__innerNavbar__item__top__right">
                                <p className="component__innerNavbar__item__top__right__name">{el.Name} ({el.Participants.length})</p>
                            </div>
                        </div>
                        <div className={`component__innerNavbar__item__bottom ${openedTeamID === el.ID ? 'component__innerNavbar__item__bottom__active' : ''}`}>
                            {el.channels.map(elem => {
                                return (
                                    <div className={`component__innerNavbar__item__bottom__item ${openedTeamChannelTab === elem.Name ? 'component__innerNavbar__item__bottom__item__active' : ''}`}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (openedTeamChannelTab !== elem.Name) {
                                                setOpenedTeamChannelTab(elem.Name);
                                            }
                                        }}
                                    >
                                        {elem.Name}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
        <div className="component__tasks__list">
            {openedTeamChannel && <TaskSystem teamChannel={openedTeamChannel} />}
        </div>

    </div>
};

export default AdminTasks;