import React from 'react';
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import rpcClient from '../../../modules/rpcClientModule';
import * as timestmapActions from "../../../actions/timestampActions";
import { changeLanguage } from "../../../actions/siteFunctionsActions";

import Spinner from "../../customComponents/Spinner";

import PropTypes from "prop-types";
import languages from '../../../languages';

export default function SettingsModal(props) {

    const languageSelector = useSelector(state => state?.siteFunctions?.language ?? 'en');

    const [activeTab, setActiveTab] = React.useState('edit');

    const userDataSelector = useSelector(state => state?.userData ?? {});

    return <div className='component__settingsModal' onClick={() => props.onClose()}>
        <div className='component__settingsModal__wrapper' onClick={(e) => e.stopPropagation()}>
            <img className='component__settingsModal__wrapper__close' src='./images/closeBtn.svg' onClick={() => props.onClose()} />
            <div className='component__settingsModal__wrapper__sidebar'>
                <p onClick={() => setActiveTab('edit')} className={`component__settingsModal__wrapper__sidebar__p ${activeTab === 'edit' ? 'settingsModal__item__active' : ''}`}>
                    <img src='./images/editProfileIcon.svg' /> {languages[languageSelector].editProfile}
                </p>
                <p onClick={() => setActiveTab('change')} 
                className={`component__settingsModal__wrapper__sidebar__p ${activeTab === 'change' ? 'settingsModal__item__active' : ''} `}> 
                <img src='./images/passwordIcon.svg' />{languages[languageSelector].changePassword}</p>
                <p onClick={() => setActiveTab('appearance')} 
                className={`component__settingsModal__wrapper__sidebar__p ${activeTab === 'appearance' ? 'settingsModal__item__active' : ''} `}> 
                <img src='./images/appearanceIcon.svg' />{languages[languageSelector].appearance}</p>
            </div>
            <div className='component__settingsModal__wrapper__content'>
                {activeTab === 'edit' && <EditProfile />}
                {activeTab === 'change' && <ChangePassword />}
                {activeTab === 'appearance' && <Appearance />}
            </div>
        </div>
    </div>
}

const EditProfile = (props) => {

    const curDispatch = useDispatch();
    const languageSelector = useSelector(state => state?.siteFunctions?.language ?? 'en');

    const userID = useSelector(state => state?.userData?.userData?.UserInfo?.ID ?? {});
    const userInfo = useSelector(state => state?.userData?.userData?.UserInfo ?? {});
    const [profileImage, setProfileImage] = React.useState();
    const [imageURL, setImageURL] = React.useState();

    const firstNameRef = React.useRef();
    const lastNameRef = React.useRef();

    const [infoP, setInfoP] = React.useState('');
    const [spinner, setSpinner] = React.useState(false)

    const uploadImage = async () => {
        if(!profileImage) return;
        setSpinner(true);
        rpcClient({
            action: "call",
            method: "staticContent.images.upload",
            params: {
                ImageFile: profileImage,
                ImageName: profileImage.name,
                ImageTag: 'profilePicture'
            },
        }).then(image => {
            if (image.status !== 'ok') {
                setInfoP({ color: 'rgb(151, 8, 8)', message: image.data });
                return;
            }else{
                console.log("image ", image)
                setImageURL(image.data);
                editProfileImage(userID, image.data);
                
            }
            setSpinner(false);
        })
    }

    const editProfile = async () => {

        if (!userID) return;
        setSpinner(true);

        rpcClient({
            action: "call",
            method: "users.editBasicUser",
            params: {
                id: userID,
                newFirstName: firstNameRef?.current?.value,
                newLastName: lastNameRef?.current?.value,
                image: imageURL ? imageURL : null
            }
        }).then(res => {
            if (res.status === "ok") {
                setInfoP({ color: 'rgb(72, 151, 8)', message: 'Profile editted successfully' });
            }
            if (res.status === "error") {
                setInfoP({color: 'rgb(151, 8, 8)', message: res.data})
            }
            setSpinner(false)
        })
    };


    const editProfileImage = async (id, image) => {

        if (!id || !image) return;
        setSpinner(true);

        rpcClient({
            action: "call",
            method: "users.editBasicUser",
            params: {
                id: id,
                image: image
            }
        }).then(res => {
            if (res.status === "ok") {
                setInfoP({ color: 'rgb(72, 151, 8)', message: 'Image changed successfully' });
                curDispatch(timestmapActions.updateTimestamp())
            }
            if (res.status === "error") {
                setInfoP({color: 'rgb(151, 8, 8)', message: res.data})
            }
            setSpinner(false)
        })
    };


    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            editProfile();
        }
    };

    React.useEffect(() => {
        uploadImage();
    }, [profileImage])


    return <div className='editProfileTab'>
        <p style={{ marginBottom: '20px' }} className='settingsTab__header'>{languages[languageSelector].editProfile}</p>
        <p className='settingsTab__label'>{languages[languageSelector].avatar}</p>
        <div className='editProfileTab__imageSection'>
            <div className='editProfileTab__imageSection__top'>
                <div className='editProfileTab__imageSection__top__left'>
                    {imageURL ? <img src={`${imageURL}`} /> : userInfo.Image ? <img src={`${userInfo.Image}`} /> : <img src='./images/logo.svg' />}
                </div>
                <div className='editProfileTab__imageSection__top__right'>
                    <p className='editProfileTab__imageSection__top__right__upload'>
                    {languages[languageSelector].uploadNewImage}
                        <input type="file" accept="image/*"
                            onChange={e => {
                                if (e.target.files.length === 1) {
                                    setProfileImage(e.target.files[0]);
                                }
                            }}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                opacity: 0,
                                zIndex: 2,
                                cursor: 'pointer'
                            }}
                        />
                    </p>
                </div>
            </div>
            <div className='settingsTab__input'>
                <img src='./images/editUserIcon.svg'></img>
                <input ref={firstNameRef} placeholder={`${languages[languageSelector].firstName}`} type='text' defaultValue={userInfo.firstName} />
            </div>
            <div className='settingsTab__input'>
                <img src='./images/editUserIcon.svg'></img>
                <input ref={lastNameRef} placeholder={`${languages[languageSelector].lastName}`} onKeyDown={handleKeyDown} type='text' defaultValue={userInfo.lastName} />
            </div>
        </div>

        <p className='settingsTab__saveBtn' onClick={() => editProfile()}>{spinner ? <Spinner color='white' style={{widht: '16px', height: '16px'}}/> : `${languages[languageSelector].saveChanges}`}</p>
        <p className='settingsTab__infoP' style={{ color: infoP.color }}>{infoP.message}</p>
    </div>
}

const ChangePassword = (props) => {

    const languageSelector = useSelector(state => state?.siteFunctions?.language ?? 'en');

    const userID = useSelector(state => state?.userData?.userData?.UserInfo?.ID ?? {});
    const [spinner, setSpinner] = React.useState(false);

    const [infoP, setInfoP] = React.useState('');

    const passwordRef = React.useRef();
    const newPasswordRef = React.useRef();
    const confirmPasswordRef = React.useRef();

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            changePw();
        }
    };

    const changePw = () => {
        setSpinner(true);

        if (newPasswordRef.current.value !== confirmPasswordRef.current.value) {
            console.log(newPasswordRef.current.value)
            console.log(confirmPasswordRef.current.value)
            setInfoP({ color: 'rgb(151, 8, 8)', message: 'Passwords are not matching' });
            setSpinner(false)
            return;
        }

        rpcClient({
            action: "call",
            method: "users.changePassword",
            params: {
                ID: userID,
                oldPassword: passwordRef.current.value,
                newPassword: newPasswordRef.current.value
            },
        }).then(pw => {
            if (pw.status === 'ok') {
                setInfoP({ color: 'rgb(72, 151, 8)', message: 'Password changed successfully' });
            } else {
                setInfoP({ color: 'rgb(151, 8, 8)', message: pw.data });
            }
            setSpinner(false)
        })
    }


    return <div className='editProfileTab'>
        <p style={{ marginBottom: '20px' }} className='settingsTab__header'>{languages[languageSelector].changePassword}</p>
        <p className='settingsTab__label'>{languages[languageSelector].password}</p>
        <div className='settingsTab__input'>
            <img src='./images/passwordIcon.svg'></img>
            <input ref={passwordRef} placeholder={`${languages[languageSelector].password}`} type='password' />
        </div>
        <p className='settingsTab__label'>{languages[languageSelector].newPassword}</p>
        <div className='settingsTab__input'>
            <img src='./images/passwordIcon.svg'></img>
            <input ref={newPasswordRef} placeholder={`${languages[languageSelector].newPassword}`} type='password' />
        </div>
        <p className='settingsTab__label'>{languages[languageSelector].confirmPassword}</p>
        <div className='settingsTab__input'>
            <img src='./images/passwordIcon.svg'></img>
            <input ref={confirmPasswordRef} onKeyDown={handleKeyDown} placeholder={`${languages[languageSelector].confirmPassword}`} type='password' />
        </div>
        <p className='settingsTab__saveBtn' onClick={() => changePw()}>{spinner ? <Spinner color='white' style={{width: '16px', height: '16px'}}/> : 'Save changes'}</p>
        <p className='settingsTab__infoP' style={{ color: infoP.color }}>{infoP.message}</p>
    </div>
}

const Appearance = props => {

    const curDispatch = useDispatch();
    const languageSelector = useSelector(state => state?.siteFunctions?.language ?? 'en');

    const handleLanguageSwitch = (newLanguage) => {
        localStorage.setItem('language', newLanguage);
        curDispatch(changeLanguage(newLanguage));
    };

    return <div className='settingsAppearance'>
        <p style={{ marginBottom: '20px' }} className='settingsTab__header'>{languages[languageSelector].appearance}</p>
        <div className='settingsAppearance__inner'>
            <div className='settingsAppearance__inner__item '>
                <img src='./images/lightThemeIcon.svg' />
                <p>{languages[languageSelector].lightTheme}</p>
            </div>
            <div className='settingsAppearance__inner__item'>
                <img src='./images/darkThemeIcon.svg' />
                <p>{languages[languageSelector].darkTheme}</p>
            </div>
        </div>
        <div className='settingsAppearance__inner'>
            <div onClick={() => handleLanguageSwitch('en')} className={`settingsAppearance__inner__item ${languageSelector === 'en' && 'activeItem'}`}>
                <img style={{width: '16px', height: '16px'}} src="./images/countries/gb.png" alt="English" />
                <p>{languages[languageSelector].english}</p>
            </div>
            <div onClick={() => handleLanguageSwitch('bs')} className={`settingsAppearance__inner__item ${languageSelector === 'bs' && 'activeItem'}`}>
                <img style={{width: '16px', height: '16px'}} src="./images/countries/ba.png" alt="Bosnian" />
                <p>{languages[languageSelector].bosnian}</p>
            </div>
        </div>

        {/* <div className={`component__sidebar__item ${languageSelector === 'en' ? "component__sidebar__item--active" : ""}`}  >
                
                <p className={`${(sidebarOpened || sidebarHovered) ? 'component__sidebar__item__opened' : ''}`}></p>
            </div>
            <div className={`component__sidebar__item ${languageSelector === 'bs' ? "component__sidebar__item--active" : ""}`} onClick={() => handleLanguageSwitch('bs')}>
                
                <p className={`${(sidebarOpened || sidebarHovered) ? 'component__sidebar__item__opened' : ''}`}></p>
            </div> */}
    </div>
}