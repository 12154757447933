import React from 'react';
import { createRoot } from "react-dom/client";
import './index.css';
import App from './App';
import { setAxiosDataHandler } from "./modules/backendModule";

import { HashRouter } from "react-router-dom"

import allReducers from "./reducers";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { setStore } from "./modules/backendModule";

setAxiosDataHandler();

let store = createStore(allReducers, composeWithDevTools());
setStore(store);

if (window.Notification?.permission !== "granted") {
    window.Notification?.requestPermission();
};

const rootElem = createRoot(document.querySelector("#root"));
rootElem.render(<Provider store={store}>
    <HashRouter>
        <App />
    </HashRouter>
</Provider>);