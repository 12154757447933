import "./index.scss";
import React from "react";

import moment from "moment";
import rpcClient from "../../../../modules/rpcClientModule";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "../../../customComponents/Spinner";


const ChatContent = (props) => {

  const userDataSelector = useSelector(state => state?.userData ?? {});

  const [allChats, setAllChats] = React.useState();
  const [paginatedChats, setPaginatedChats] = React.useState();
  const [allUsers, setAllUsers] = React.useState();
  const [spinner, setSpinner] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isPaginating, setIsPaginating] = React.useState(false);
  const [canPaginate, setCanPaginate] = React.useState(false);

  const [showScrollArrow, setShowScrollArrow] = React.useState(false);

  const unsubscribeRef = React.useRef();
  const contentRef = React.useRef(null);
  const observerRef = React.useRef();

  const subscribeToChatRoom = () => {

    if (!props.chatRoomID) return;

    rpcClient({
      action: "subscribe",
      method: "chatRooms.subscribe",
      params: {
        chatRoomID: props.chatRoomID
      },
      subscribeDataCallback: (d, item) => {
        setAllChats(ac => {
          if (!ac) return ac;
          if (ac.status !== 'ok') return ac;
          return { ...ac, data: [...ac.data, d] }
        })
      },
      subscribeOpenedCallback: unsub => { unsubscribeRef.current = unsub }
    }).then(res => {
    })
  }

  const getAllChats = () => {
    if (!props.chatRoomID) return;

    rpcClient({
      action: "call",
      method: "chats.getAll",
      params: {
        chatRoomID: props.chatRoomID,
        limit: 20
      },
    }).then(chats => {
      if (chats.status === 'ok') {
        setAllChats({ status: 'ok', data: chats.data.reverse() });
        if (chats.data.length === 20) setCanPaginate(true);
        else setCanPaginate(false);
      }
    });
  };


  const getMoreChats = () => {
    if (!canPaginate || !props.chatRoomID || loading) return;

    setLoading(true);
    setIsPaginating(true);

    let allIDs = allChats.data.map(el => el.ID);
    if (paginatedChats?.data?.length) {
      allIDs = [...allIDs, paginatedChats.data.map(el => el.ID)]
    }

    rpcClient({
      action: "call",
      method: "chats.getAll",
      params: {
        chatRoomID: props.chatRoomID,
        limit: 20,
        filters: [{ name: 'ID', op: 'notIn', value: allIDs }]
      },
    }).then(chats => {
      if (chats.status === 'ok') {
        const newChats = chats.data.reverse();
        if (paginatedChats?.data?.length) {
          setPaginatedChats(prevChats => ({
            status: 'ok',
            data: [...newChats, ...prevChats?.data]
          }));
        } else {
          setPaginatedChats({
            status: 'ok',
            data: [...newChats]
          });
        }

        if (chats.data.length === 20) setCanPaginate(true);
        else setCanPaginate(false);
      }
      setIsPaginating(false);
      setLoading(false);
    }).catch(() => {
      setLoading(false);
      setIsPaginating(false);
    });
  };

  const scrollToBottom = () => {
    if (contentRef.current) {
      contentRef.current.scrollTo({
        top: contentRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  };


  // HANDLERS BELOW
  const handleIntersection = (entries) => {
    const target = entries[0];
    if (target.isIntersecting && !loading) {
      getMoreChats();
    }
  };

  const handleScroll = () => {
    if (contentRef.current) {
      const isScrolledUp = contentRef.current.scrollTop < contentRef.current.scrollHeight - contentRef.current.clientHeight - 20;
      setShowScrollArrow(isScrolledUp);
    }
  };
  // END OF HANDLERS

  // REGEX HANDLING BELOW
  const isOnlyEmoji = (content) => {
    const emojiRegex = /^[^\d\s]*([\p{Emoji}])+/u; // Match strings that contain at least one emoji and no digits or whitespace
    const isEmojiOnly = emojiRegex.test(content) && !/\d/.test(content); // Ensure it does not contain digits
    return isEmojiOnly;
  };
  const extractUrlParts = (text) => {
    const urlRegex = /https?:\/\/[^\s]+|www\.[^\s]+/g;
    const parts = text.split(urlRegex);
    const urls = text.match(urlRegex) || [];

    return parts.map((part, index) => ({
      text: part,
      isUrl: index < urls.length
    })).flatMap((part, index) => {
      if (part.isUrl) {
        let url = urls.shift();
        if (!/^https?:\/\//i.test(url)) {
          url = `https://${url}`;
        }
        return [{ text: url, isUrl: true }];
      }
      return [part];
    });
  };
  // END OF REGEX HANDLING


  React.useEffect(() => {
    const observerElement = observerRef.current;
    const observer = new IntersectionObserver(handleIntersection, {
      root: contentRef.current,
      threshold: 1.0,
    });

    if (observerElement) {
      observer.observe(observerElement);
    }

    return () => {
      if (observerElement) {
        observer.unobserve(observerElement);
      }
    };
  }, [loading, allChats]);


  React.useEffect(() => {
    getAllChats();
    subscribeToChatRoom();

    return () => {
      if (typeof (unsubscribeRef.current) === 'function') {
        unsubscribeRef.current();
      }
    };
  }, [props?.chatRoomID]);

  React.useEffect(() => {
    setTimeout(() => {
      if (contentRef.current) {
        contentRef.current.scrollTo({
          top: contentRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }
    }, 500);
  }, []);

  React.useEffect(() => {
    const contentElement = contentRef.current;
    if (contentElement) {
      // Scroll to the bottom on mount
      scrollToBottom();

      // Add scroll event listener
      contentElement.addEventListener('scroll', handleScroll);

      return () => {
        // Clean up the event listener on unmount
        contentElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  React.useEffect(() => {
    if (!isPaginating && contentRef.current) {
      contentRef.current.scrollTo({
        top: contentRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [allChats]);

  React.useEffect(() => {
    rpcClient({
      action: "call",
      method: "users.getAll",
      callback: d => setAllUsers(d)
    });
  }, []);

  return <div className="chatBody__content">

    <div className={`chatBody__content__scrollArrow ${showScrollArrow ? 'visible' : 'hidden'}`} onClick={scrollToBottom}>
      <img src="./images/scrollArrow.svg" />
    </div>

    <div className="chatBody__content__wrap" ref={contentRef}>
      {loading ? <Spinner style={{ width: '50px', height: '50px', margin: '0px auto' }} /> : <div ref={observerRef} style={{ height: '1px' }}></div>}

      {/* PAGINATED CHATS */}
      {paginatedChats?.status === 'ok' && allUsers?.status === 'ok' && allUsers?.data?.length && paginatedChats?.data?.map((el, index) => {
        const user = allUsers.data.find(elem => elem.ID === el.From);
        const time = moment(el.createdAt).format('HH:mm');
        const currentDate = moment(el.createdAt).startOf('day');
        const previousDate = index > 0 ? moment(paginatedChats.data[index - 1].createdAt).startOf('day') : null;

        // Check if the date is different from the previous message
        const isDifferentDay = !previousDate || !currentDate.isSame(previousDate, 'day');
        const isDifferentUser = index > 0 && paginatedChats.data[index - 1].From !== el.From;
        const messageStyle = isDifferentUser ? { marginTop: '16px' } : {};

        // Determine the date label
        let dateLabel = '';
        if (isDifferentDay) {
          if (currentDate.isSame(moment(), 'day')) {
            dateLabel = 'Today';
          } else if (currentDate.isSame(moment().subtract(1, 'day'), 'day')) {
            dateLabel = 'Yesterday';
          } else {
            dateLabel = currentDate.format('MMMM Do, YYYY'); // Format date as actual date
          }
        }

        return (
          <React.Fragment key={el.ID}>
            {/* Render date label if it's a different day */}
            {isDifferentDay && (
              <div className="chatBody__content__wrap__dateSeparator">
                <div className="chatBody__content__wrap__dateSeparator__line"></div>
                <span>{dateLabel}</span>
              </div>
            )}

            {/* My messages */}
            {el.From === userDataSelector?.userData?.UserInfo.ID ? (
              <div className="chatBody__content__wrap__myMsg" style={messageStyle} title={moment(el.createdAt).toDate().toLocaleString()}>
                {isDifferentUser && <p className="chatBody__content__wrap__myMsg__time">{time}</p>}
                {el.Type === 'text' && (
                  extractUrlParts(el.Content).map((part, index) =>
                    part.isUrl ? (
                      <a key={index} href={part.text} target="_blank" rel="noopener noreferrer" className="chatBody__content__wrap__myMsg__content">
                        {part.text}
                      </a>
                    ) : (
                      <span key={index} style={isOnlyEmoji(part.text) ? { fontSize: '40px' } : {}} className="chatBody__content__wrap__myMsg__content">{part.text}</span>
                    )
                  )
                )}
                {el.Type === 'image' && <p className="chatBody__content__wrap__myMsg__content"><img src={el.Content} alt="chat image" /></p>}
              </div>
            ) : (
              /* Other users' messages */
              <div className="chatBody__content__wrap__othersMsg" style={messageStyle} title={moment(el.createdAt).toDate().toLocaleString()}>
                <div className="chatBody__content__wrap__othersMsg__left" style={{ display: `${isDifferentUser ? 'block' : 'none'}` }}>
                  {user && user?.Image?.length ? <img src={`${user.Image}`} alt="user image" /> : <img src="../images/logo.svg" alt="default image" />}
                </div>
                <div className="chatBody__content__wrap__othersMsg__right" style={{ marginLeft: `${isDifferentUser ? '0' : '58px'}` }}>
                  {isDifferentUser && (
                    <p className="chatBody__content__wrap__othersMsg__right__time">
                      {user?.FirstName && user?.LastName ? `${user?.FirstName} ${user?.LastName}` : user?.Username || 'DeletedUser'} <span>{time}</span>
                    </p>
                  )}
                  {el.Type === 'text' && (
                    extractUrlParts(el.Content).map((part, index) =>
                      part.isUrl ? (
                        <a key={index} href={part.text} target="_blank" rel="noopener noreferrer" className="chatBody__content__wrap__othersMsg__right__content">
                          {part.text}
                        </a>
                      ) : (
                        <span key={index} style={isOnlyEmoji(part.text) ? { fontSize: '40px' } : {}} className="chatBody__content__wrap__othersMsg__right__content">{part.text}</span>
                      )
                    )
                  )}
                  {el.Type === 'image' && <p className="chatBody__content__wrap__othersMsg__right__content"><img src={el.Content} alt="chat image" /></p>}
                </div>
              </div>
            )}
          </React.Fragment>
        );
      })}


      {/* INITIAL CHATS THAT ARE LOADED */}
      {allChats?.status === 'ok' && allUsers?.status === 'ok' && allUsers?.data?.length ? allChats?.data?.map((el, index) => {
        const user = allUsers.data.find(elem => elem.ID === el.From);
        const time = moment(el.createdAt).format('HH:mm');
        const currentDate = moment(el.createdAt).startOf('day');
        const previousDate = index > 0 ? moment(allChats.data[index - 1].createdAt).startOf('day') : null;

        // Check if the date is different from the previous message
        const isDifferentDay = !previousDate || !currentDate.isSame(previousDate, 'day');
        const isDifferentUser = index > 0 && allChats.data[index - 1].From !== el.From;
        const messageStyle = isDifferentUser ? { marginTop: '16px' } : {};

        // Determine the date label
        let dateLabel = '';
        if (isDifferentDay) {
          if (currentDate.isSame(moment(), 'day')) {
            dateLabel = 'Today';
          } else if (currentDate.isSame(moment().subtract(1, 'day'), 'day')) {
            dateLabel = 'Yesterday';
          } else {
            dateLabel = currentDate.format('MMMM Do, YYYY');
          }
        }

        return (
          <React.Fragment key={el.ID}>
            {/* Render date label if it's a different day */}
            {isDifferentDay && (
              <div className="chatBody__content__wrap__dateSeparator">
                <div className="chatBody__content__wrap__dateSeparator__line"></div>
                <span>{dateLabel}</span>
              </div>
            )}

            {/* My messages */}
            {el.From === userDataSelector?.userData?.UserInfo.ID ? (
              <div className="chatBody__content__wrap__myMsg" style={messageStyle} title={moment(el.createdAt).toDate().toLocaleString()}>
                {isDifferentUser && <p className="chatBody__content__wrap__myMsg__time">{time}</p>}
                {el.Type === 'text' && (
                  extractUrlParts(el.Content).map((part, index) =>
                    part.isUrl ? (
                      <a key={index} href={part.text} target="_blank" rel="noopener noreferrer" className="chatBody__content__wrap__myMsg__content">
                        {part.text}
                      </a>
                    ) : (
                      <span key={index} style={isOnlyEmoji(part.text) ? { fontSize: '40px' } : {}} className="chatBody__content__wrap__myMsg__content">{part.text}</span>
                    )
                  )
                )}
                {el.Type === 'image' && <p className="chatBody__content__wrap__myMsg__content"><img src={el.Content} alt="chat image" /></p>}
              </div>
            ) : (
              /* Other users' messages */
              <div className="chatBody__content__wrap__othersMsg" style={messageStyle} title={moment(el.createdAt).toDate().toLocaleString()}>
                <div className="chatBody__content__wrap__othersMsg__left" style={{ display: `${isDifferentUser ? 'block' : 'none'}` }}>
                  {user && user?.Image?.length ? <img src={`${user.Image}`} alt="user image" /> : <img src="../images/logo.svg" alt="default image" />}
                </div>
                <div className="chatBody__content__wrap__othersMsg__right" style={{ marginLeft: `${isDifferentUser ? '0' : '58px'}` }}>
                  {isDifferentUser && (
                    <p className="chatBody__content__wrap__othersMsg__right__time">
                      {user?.FirstName && user?.LastName ? `${user?.FirstName} ${user?.LastName}` : user?.Username || 'DeletedUser'} <span>{time}</span>
                    </p>
                  )}
                  {el.Type === 'text' && (
                    extractUrlParts(el.Content).map((part, index) =>
                      part.isUrl ? (
                        <a key={index} href={part.text} target="_blank" rel="noopener noreferrer" className="chatBody__content__wrap__othersMsg__right__content">
                          {part.text}
                        </a>
                      ) : (
                        <span key={index} style={isOnlyEmoji(part.text) ? { fontSize: '40px' } : {}} className="chatBody__content__wrap__othersMsg__right__content">{part.text}</span>
                      )
                    )
                  )}
                  {el.Type === 'image' && <p className="chatBody__content__wrap__othersMsg__right__content"><img src={el.Content} alt="chat image" /></p>}
                </div>
              </div>
            )}
          </React.Fragment>
        );
      }) : <p className="chatBody__content__wrap__message">Please select a chat from the left sidebar</p>}

    </div>

  </div>
}

export default ChatContent