const emojiCategories = {
    Smileys: [
        { emoji: "😀", keyword: ":smile:" },
        { emoji: "😂", keyword: ":joy:" },
        { emoji: "😅", keyword: ":sweat_smile:" },
        { emoji: "😍", keyword: ":heart_eyes:" },
        { emoji: "😎", keyword: ":sunglasses:" },
        { emoji: "🤔", keyword: ":thinking:" },
        { emoji: "😇", keyword: ":innocent:" },
        { emoji: "🥳", keyword: ":party:" },
        { emoji: "😡", keyword: ":angry:" },
        { emoji: "😢", keyword: ":cry:" },
        { emoji: "😜", keyword: ":wink:" },
        { emoji: "🤩", keyword: ":star_struck:" },
        { emoji: "😱", keyword: ":scream:" },
        { emoji: "😷", keyword: ":mask:" },
        { emoji: "🤐", keyword: ":zipper_mouth:" },
        { emoji: "🥺", keyword: ":pleading:" },
        { emoji: "🤓", keyword: ":nerd:" },
        { emoji: "🤯", keyword: ":mind_blown:" },
        { emoji: "😴", keyword: ":sleeping:" },
        { emoji: "🤤", keyword: ":drooling:" },
        { emoji: "😈", keyword: ":smiling_devil:" },
        { emoji: "👿", keyword: ":angry_devil:" },
        { emoji: "💀", keyword: ":skull:" },
        { emoji: "👻", keyword: ":ghost:" },
        { emoji: "🤡", keyword: ":clown:" },
        { emoji: "😬", keyword: ":grimacing:" },
        { emoji: "🤥", keyword: ":lying_face:" },
        { emoji: "😖", keyword: ":confounded:" },
        { emoji: "🥶", keyword: ":cold_face:" },
        { emoji: "🥵", keyword: ":hot_face:" },
        { emoji: "🥴", keyword: ":woozy_face:" },
        { emoji: "🤪", keyword: ":zany_face:" },
        { emoji: "😤", keyword: ":triumph:" },
        { emoji: "😩", keyword: ":weary:" },
        { emoji: "😆", keyword: ":laughing:" },
        { emoji: "😋", keyword: ":yum:" },
        { emoji: "🤗", keyword: ":hugging:" }
    ],
    Hearts: [
        { emoji: "❤️", keyword: ":heart:" },
        { emoji: "💖", keyword: ":sparkling_heart:" },
        { emoji: "💔", keyword: ":broken_heart:" },
        { emoji: "💕", keyword: ":two_hearts:" },
        { emoji: "💘", keyword: ":heart_with_arrow:" },
        { emoji: "💗", keyword: ":growing_heart:" },
        { emoji: "💓", keyword: ":beating_heart:" },
        { emoji: "💞", keyword: ":revolving_hearts:" },
        { emoji: "💟", keyword: ":heart_decoration:" },
        { emoji: "❣️", keyword: ":heart_exclamation:" },
        { emoji: "💚", keyword: ":green_heart:" },
        { emoji: "💙", keyword: ":blue_heart:" },
        { emoji: "💛", keyword: ":yellow_heart:" },
        { emoji: "💜", keyword: ":purple_heart:" },
        { emoji: "🖤", keyword: ":black_heart:" },
        { emoji: "🤍", keyword: ":white_heart:" },
        { emoji: "💝", keyword: ":heart_with_ribbon:" },
        { emoji: "💌", keyword: ":love_letter:" },
        { emoji: "💏", keyword: ":kiss:" },
        { emoji: "💑", keyword: ":couple_with_heart:" }
    ],
    Gestures: [
        { emoji: "👍", keyword: ":thumbs_up:" },
        { emoji: "👎", keyword: ":thumbs_down:" },
        { emoji: "👏", keyword: ":clap:" },
        { emoji: "👌", keyword: ":ok_hand:" },
        { emoji: "🤞", keyword: ":fingers_crossed:" },
        { emoji: "🤙", keyword: ":call_me:" },
        { emoji: "✌️", keyword: ":victory:" },
        { emoji: "🤟", keyword: ":love_you:" },
        { emoji: "🖕", keyword: ":finger:" },
        { emoji: "🙌", keyword: ":raised_hands:" },
        { emoji: "👋", keyword: ":wave:" },
        { emoji: "🤝", keyword: ":handshake:" },
        { emoji: "🙏", keyword: ":pray:" },
        { emoji: "👐", keyword: ":open_hands:" },
        { emoji: "🤲", keyword: ":palms_up:" },
        { emoji: "✊", keyword: ":fist:" },
        { emoji: "🤚", keyword: ":raised_back_hand:" },
        { emoji: "✋", keyword: ":raised_hand:" },
        { emoji: "🤜", keyword: ":fist_right:" },
        { emoji: "🤛", keyword: ":fist_left:" },
        { emoji: "👆", keyword: ":point_up:" },
        { emoji: "👇", keyword: ":point_down:" },
        { emoji: "👉", keyword: ":point_right:" },
        { emoji: "👈", keyword: ":point_left:" }
    ],
    Animals: [
        { emoji: "🐶", keyword: ":dog:" },
        { emoji: "🐱", keyword: ":cat:" },
        { emoji: "🐭", keyword: ":mouse:" },
        { emoji: "🐹", keyword: ":hamster:" },
        { emoji: "🐰", keyword: ":rabbit:" },
        { emoji: "🦊", keyword: ":fox:" },
        { emoji: "🐻", keyword: ":bear:" },
        { emoji: "🐼", keyword: ":panda:" },
        { emoji: "🐨", keyword: ":koala:" },
        { emoji: "🐯", keyword: ":tiger:" },
        { emoji: "🦁", keyword: ":lion:" },
        { emoji: "🐷", keyword: ":pig:" },
        { emoji: "🐸", keyword: ":frog:" },
        { emoji: "🐵", keyword: ":monkey:" },
        { emoji: "🐔", keyword: ":chicken:" },
        { emoji: "🦄", keyword: ":unicorn:" },
        { emoji: "🐧", keyword: ":penguin:" },
        { emoji: "🦉", keyword: ":owl:" },
        { emoji: "🐒", keyword: ":monkey_face:" },
        { emoji: "🦓", keyword: ":zebra:" },
        { emoji: "🐘", keyword: ":elephant:" },
        { emoji: "🦒", keyword: ":giraffe:" },
        { emoji: "🐦", keyword: ":bird:" },
        { emoji: "🦜", keyword: ":parrot:" },
        { emoji: "🦅", keyword: ":eagle:" },
        { emoji: "🐍", keyword: ":snake:" },
        { emoji: "🦋", keyword: ":butterfly:" },
        { emoji: "🐛", keyword: ":bug:" },
        { emoji: "🦖", keyword: ":t_rex:" }
    ],
    Nature: [
        { emoji: "🌳", keyword: ":tree:" },
        { emoji: "🌴", keyword: ":palm_tree:" },
        { emoji: "🌷", keyword: ":tulip:" },
        { emoji: "🌸", keyword: ":cherry_blossom:" },
        { emoji: "🌼", keyword: ":blossom:" },
        { emoji: "🌻", keyword: ":sunflower:" },
        { emoji: "🌹", keyword: ":rose:" },
        { emoji: "🌺", keyword: ":hibiscus:" },
        { emoji: "🍁", keyword: ":maple_leaf:" },
        { emoji: "🍂", keyword: ":fallen_leaf:" },
        { emoji: "🍃", keyword: ":leaf_fluttering_in_wind:" },
        { emoji: "🌾", keyword: ":ear_of_rice:" },
        { emoji: "🌵", keyword: ":cactus:" },
        { emoji: "🍄", keyword: ":mushroom:" },
        { emoji: "🎋", keyword: ":tanabata_tree:" },
        { emoji: "🎄", keyword: ":christmas_tree:" },
        { emoji: "🌙", keyword: ":crescent_moon:" },
        { emoji: "⭐", keyword: ":star:" },
        { emoji: "⚡", keyword: ":lightning:" },
        { emoji: "🔥", keyword: ":fire:" },
        { emoji: "🌈", keyword: ":rainbow:" },
        { emoji: "🌊", keyword: ":wave:" },
        { emoji: "☀️", keyword: ":sun:" },
        { emoji: "🌧️", keyword: ":cloud_rain:" },
        { emoji: "❄️", keyword: ":snowflake:" }
    ],
    Food: [
        { emoji: "🍎", keyword: ":apple:" },
        { emoji: "🍌", keyword: ":banana:" },
        { emoji: "🍇", keyword: ":grapes:" },
        { emoji: "🍉", keyword: ":watermelon:" },
        { emoji: "🍊", keyword: ":tangerine:" },
        { emoji: "🍍", keyword: ":pineapple:" },
        { emoji: "🍑", keyword: ":peach:" },
        { emoji: "🥭", keyword: ":mango:" },
        { emoji: "🥝", keyword: ":kiwi:" },
        { emoji: "🍓", keyword: ":strawberry:" },
        { emoji: "🍒", keyword: ":cherries:" },
        { emoji: "🍆", keyword: ":eggplant:" },
        { emoji: "🥑", keyword: ":avocado:" },
        { emoji: "🌽", keyword: ":corn:" },
        { emoji: "🥕", keyword: ":carrot:" },
        { emoji: "🥔", keyword: ":potato:" },
        { emoji: "🍔", keyword: ":burger:" },
        { emoji: "🍟", keyword: ":fries:" },
        { emoji: "🍕", keyword: ":pizza:" },
        { emoji: "🍗", keyword: ":chicken_leg:" },
        { emoji: "🥪", keyword: ":sandwich:" },
        { emoji: "🍣", keyword: ":sushi:" },
        { emoji: "🍜", keyword: ":ramen:" },
        { emoji: "🍰", keyword: ":cake:" },
        { emoji: "🍫", keyword: ":chocolate:" },
        { emoji: "🍦", keyword: ":icecream:" },
        { emoji: "🍩", keyword: ":doughnut:" },
        { emoji: "🍪", keyword: ":cookie:" },
        { emoji: "🍯", keyword: ":honey_pot:" },
        { emoji: "🥤", keyword: ":drink:" },
        { emoji: "🍸", keyword: ":cocktail:" }
    ]
};
export default emojiCategories;