import "./index.scss";
import React from "react";

import axios, { all } from "axios";
import moment from "moment";
import rpcClient from "../../../../modules/rpcClientModule";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "../../../customComponents/Spinner";

import emojiCategories from '../emojiMap';


const ChatBottom = (props) => {

  const [selectedImages, setSelectedImages] = React.useState([]);
  const [spinner, setSpinner] = React.useState(false);

  const [showEmojiPicker, setShowEmojiPicker] = React.useState(false);
  const [message, setMessage] = React.useState('');

  const [activeCategory, setActiveCategory] = React.useState('Smileys');

  const chatInputRef = React.useRef();

  const emojiMap = Object.values(emojiCategories).reduce((map, category) => {
    category.forEach(({ emoji, keyword }) => {
      map[keyword] = emoji;
    });
    return map;
  }, {});


  const sendChat = async () => {

    if(!chatInputRef.current.value.length) return;

    if (selectedImages.length) {
      setSpinner(true);
      if (chatInputRef.current.value) {
        await rpcClient({
          action: "call",
          method: "chats.sendChat",
          params: {
            Content: chatInputRef.current.value,
            Type: 'text',
            ChatRoomID: props.chatRoomID
          },
        }).then(() => {
          setMessage('');
        })
      }
      for (let item of selectedImages) {
        await rpcClient({
          action: "call",
          method: "staticContent.images.upload",
          params: {
            ImageFile: item.file,
            ImageName: item.file.name,
            ImageTag: 'chatImage'
          },
          callback: c => {
            if (c?.status !== 'ok') return;
            rpcClient({
              action: "call",
              method: "chats.sendChat",
              params: {
                Content: c.data,
                Type: 'image',
                ChatRoomID: props.chatRoomID
              },
            })
          }
        })
      }
      props.onChange();
      setSelectedImages([])
      setSpinner(false);
      return
    }

    rpcClient({
      action: "call",
      method: "chats.sendChat",
      params: {
        Content: chatInputRef.current.value,
        Type: 'text',
        ChatRoomID: props.chatRoomID
      },
    }).then(() => {
      setMessage('');
      setSpinner(false);
      props.onChange();
    })
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendChat();
    }
  };

  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    const newImages = files.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
      uploaded: false,
      progress: 0
    }));

    setSelectedImages((prevImages) => [...prevImages, ...newImages]);
  };

  const handlePaste = (event) => {
    const clipboardItems = event.clipboardData.items;

    for (let i = 0; i < clipboardItems.length; i++) {
      const item = clipboardItems[i];

      if (item.type.startsWith('image/')) {
        const file = item.getAsFile();
        const imageUrl = URL.createObjectURL(file);

        const newImage = {
          file,
          preview: imageUrl,
          uploaded: false,
          progress: 0
        };

        setSelectedImages((prevImages) => [...prevImages, newImage]);
      }
    }
  };

  const handleEmojiClick = (emojiData) => {
    const input = chatInputRef.current;
    const startPos = input.selectionStart;
    const endPos = input.selectionEnd;

    const newMessage = message.slice(0, startPos) + emojiData.emoji + message.slice(endPos);

    setMessage(newMessage);
    input.focus();
    setShowEmojiPicker(false);
  };

  const handleChange = (e) => {
    const input = e.target.value;
    const updatedMessage = input.replace(/:(\w+):/g, (match, keyword) => {
      return emojiMap[match] || match;
    });

    setMessage(updatedMessage);
  };

  return <div className="chatBody__input">
    <div className="chatBody__input__top">
      <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src="./images/addImage.svg" alt="Upload" style={{ cursor: 'pointer' }} />
        <input
          type="file"
          multiple
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            opacity: 0,
            cursor: 'pointer',
          }}
          onChange={handleFileUpload}
        />
      </div>
      <textarea ref={chatInputRef}
        placeholder="Type a new message"
        onKeyDown={handleKeyPress}
        onPaste={handlePaste}
        value={message}
        onChange={handleChange}
      />
      <div style={{ position: 'relative' }}>
        <img
          src="./images/emojis.svg"
          alt="Emojis"
          style={{ cursor: 'pointer' }}
          onClick={() => setShowEmojiPicker(!showEmojiPicker)}
        />
        {showEmojiPicker && (
          <div
            style={{
              position: 'absolute',
              bottom: '50px',
              right: '0',
              // background: '#141718',
              // border: '1px solid #343839',
              backgroundColor: 'rgba(0, 0, 0, 0.85)',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              padding: '10px',
              borderRadius: '10px',
            }}
          >
            {/* Emojis */}
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {emojiCategories[activeCategory].map((emojiData, index) => (
                <span
                  key={index}
                  style={{ fontSize: '20px', margin: '5px', cursor: 'pointer' }}
                  onClick={() => handleEmojiClick(emojiData)}
                >
                {emojiData.emoji}
                </span>
              ))}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              {Object.keys(emojiCategories).map((category) => (
                <button
                  key={category}
                  style={{
                    padding: '5px 10px',
                    border: 'none',
                    background: activeCategory === category ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.95)',
                    cursor: 'pointer',
                    borderBottom: activeCategory === category ? '2px solid #fff' : 'none',
                  }}
                  onClick={() => setActiveCategory(category)}
                >
                {emojiCategories[category][0].emoji}
                </button>
              ))}
            </div>

            
          </div>
        )}
      </div>
    </div>
    <div className={`chatBody__input__bottom ${selectedImages.length && 'chatBody__input__bottom__open'}`}>
      {selectedImages.map((image, index) => (
        <div key={index} style={{ textAlign: 'center', position: 'relative' }}>
          <img
            src={image.preview}
            alt="preview"
            style={{
              width: '70px',
              height: 'auto',
              objectFit: 'cover',
            }}
          />
        </div>
      ))}
    </div>
  </div>
}

export default ChatBottom