const initialState = {
    headerRefreshHandlers: [],
    siteTrackingProfilesTimestamp: Date.now(),
    stickyHeader: true,
    stickySidebar: false,
    headerComponents: [],
    theme: "dark",
    language: localStorage.getItem('language') || 'en'
};

export default (state = initialState, action) => {
    switch(action.type) {
        case "SITE_FUNCTIONS_ADD_HEADER_REFRESH":
            return {
                ...state,
                headerRefreshHandlers: [
                    ...state.headerRefreshHandlers,
                    action.payload
                ]
            };
        case "SITE_FUNCTIONS_REMOVE_HEADER_REFRESH":
            return {
                ...state,
                headerRefreshHandlers: state.headerRefreshHandlers.filter(t => t !== action.payload)
            };
        case "SITE_FUNCTIONS_UPDATE_TRACKING_TIMESTAMP":
            return {
                ...state,
                siteTrackingProfilesTimestamp: Date.now()
            };
        case "SITE_FUNCTIONS_UPDATE_STICKY_HEADER":
            return {
                ...state,
                stickyHeader: !!action.payload
            };
        case "SITE_FUNCTIONS_UPDATE_STICKY_SIDEBAR":
            return {
                ...state,
                stickySidebar: !!action.payload
            };
        case "SITE_FUNCTIONS_CHANGE_HEADER_COMPONENTS":
            return {
                ...state,
                headerComponents: action.payload
            };
        case "SITE_FUNCTIONS_CHANGE_THEME":
            return {
                ...state,
                theme: action.payload
            }
        case "SITE_FUNCTIONS_CHANGE_LANGUAGE": 
            return {
                ...state,
                language: action.payload
            };
        default: return state;
    };
};