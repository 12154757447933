import "./index.scss";
import React from "react";

import { useDispatch, useSelector } from "react-redux";
import rpcClient from "../../../../modules/rpcClientModule";

import { animateBox } from "../../../../modules/componentAnimation"


const ChatHeader = (props) => {

    return <div className="chatBody__head">
        <img src={props?.chatImage} alt="Chat Image" />
        <p className="chatBody__head__name">{props?.chatName}</p>
        {props?.integrations && <div className="chatBody__head__right">
            {/* {props?.integrations && props.integrations.map(el => {
                return <p className="chatBody__head__right__item">{el.Type}</p>
            })} */}
            {props?.participants && <p onClick={(e) => animateBox(e, <ChannelMembersModal participants={props?.participants} />)}><img src='./images/teamMembersIcon.svg' /></p>}
        </div>}
        {!props?.integrations && props?.participants && 
        <p style={{marginLeft: 'auto', display: 'flex', alignItmes: 'center'}} onClick={(e) => animateBox(e, <ChannelMembersModal participants={props?.participants} />)}>
            <img style={{width: '20px', height: '20px'}} src='./images/teamMembersIcon.svg' />
        </p>}
        
    </div>
}


const ChannelMembersModal = (props) => {

    const userInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});
    const [spinner, setSpinner] = React.useState(false)
    const [curParticipants, setCurParticipants] = React.useState([]);


    const getUsers = (ids) => {
        if(!ids) return;

        let filters = [{name: 'ID', op: 'in', value: ids}]

        rpcClient({
            action: "call",
            method: "users.getAll",
            params: {
                filters : filters
            },
            callback: d => setCurParticipants(d)
        })
    }
    
    React.useEffect(() => {
        if (props.participants && props.participants.length > 0) {
          const participantIds = props.participants.map(p => p.ID);
          getUsers(participantIds)
        }
    }, [props.participants]);


    return <div className="channelMembersModal" onClick={() => props.onClose()}>
        <div className="channelMembersModal__wrapper" onClick={(e) => e.stopPropagation()}>
            <img className="channelMembersModal__wrapper__close" src="./images/closeBtn.svg" onClick={() => props.onClose()} />
            <p className="channelMembersModal__wrapper__headline">Channel Members</p>
            {(userInfoSelector?.Flags?.isAdmin || userInfoSelector?.Flags?.isAdmin) && curParticipants.status === 'ok' && <div className="channelMembersModal__wrapper__members">
                {curParticipants?.data?.map(el => {
                    return <div className="channelMembersModal__wrapper__members__item">{el?.Image ? <img src={`${el.Image}`}></img> : <img src="./images/logo.svg"></img>} {el.FirstName} {el.LastName}</div>
                })}
            </div>}
        </div>
    </div>

}

export default ChatHeader