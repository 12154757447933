import "./index.scss";
import React from "react";

import axios from "axios";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import rpcClient from "../../modules/rpcClientModule";
import { updateTimestamp } from "../../actions/timestampActions";
import { updateStickySidebar } from "../../actions/siteFunctionsActions";
import * as notificationsActions from "../../actions/notificationsActions";

import SettingsModal from "../modals/SettingsModal";

import animateModule from "../../modules/animateModule";
import { animateBox } from "../../modules/componentAnimation";


const Notifications = props => {

    const notificationsSelector = useSelector(state => state?.notifications ?? {});
    const curDispatch = useDispatch();

    const [timerWidth, setTimerWidth] = React.useState(100);
    const [activeNotificationID, setActiveNotificationID] = React.useState(null);

    const notificationSound = React.useRef(new Audio('/notification.mp3'));

    const totalDuration = 10000;
    const intervalDuration = 100; 
    const decrementAmount = (100 / (totalDuration / intervalDuration));

    const removeNotification = (id) => {
        curDispatch(notificationsActions.removeNotification(id))
        setTimerWidth(100);
        rpcClient({
            action: "call",
            method: "notifications.updateNotification",
            params: {
                ID: id
            }
        })
        setActiveNotificationID(null);
    };

    const clearAllNotifications = () => {
        curDispatch(notificationsActions.removeAllNotifications());
        setActiveNotificationID(null); 
    };

    React.useEffect(() => {
        if (notificationsSelector.notifications.length > 0) {
            if (activeNotificationID === null) {
                setActiveNotificationID(notificationsSelector.notifications[0].ID); 
                notificationSound.current.play()
            }
        }
    }, [notificationsSelector.notifications, activeNotificationID]);

    React.useEffect(() => {
        let interval, timeout;
    
        const handleFocus = () => {
            if (activeNotificationID !== null) {
                interval = setInterval(() => {
                    setTimerWidth((prev) => Math.max(prev - decrementAmount, 0)); 
                }, intervalDuration);
    
                timeout = setTimeout(() => {
                    removeNotification(activeNotificationID);
                }, totalDuration);
            }
        };
    
        const handleBlur = () => {
            if (interval) clearInterval(interval);
            if (timeout) clearTimeout(timeout);
        };
    
        if (activeNotificationID !== null) {
            handleFocus();
    
            window.addEventListener('focus', handleFocus);
            window.addEventListener('blur', handleBlur);
        }
    
        return () => {
            if (interval) clearInterval(interval);
            if (timeout) clearTimeout(timeout);
            window.removeEventListener('focus', handleFocus);
            window.removeEventListener('blur', handleBlur);
        };
    }, [activeNotificationID]);


    return (
        <>
            {notificationsSelector?.notifications?.length > 0 && (
                <div className="notifications">
                    <div className="notifications__items">
                        {notificationsSelector.notifications.map((notification) => (
                            <NotificationItem
                                key={notification.ID}
                                notification={notification}
                                removeNotification={removeNotification}
                                timerWidth={notification.ID === activeNotificationID ? timerWidth : 100}
                            />
                        ))}
                    </div>
                    <p className="notifications__clearAll" onClick={clearAllNotifications}>Clear All</p>
                </div>
            )}
        </>
    );
}

const NotificationItem = ({ notification, removeNotification, timerWidth }) => {

    const curNavigate = useNavigate();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".root__content"));

    const [isClosing, setIsClosing] = React.useState(false);

    const handleRemove = () => {
        setIsClosing(true); 
        setTimeout(() => {
            removeNotification(notification.ID);
        }, 300);
    };

    const handleNotificationClick = () => {
        console.log("ovo je notification ", notification)
        switch (notification.Action) {
            case 'open-tasks':
                animateNavigate(`/tasks?taskID=${notification?.AdditionalData?.taskID}&teamChannelID=${notification?.AdditionalData?.teamChannelID}`);
                handleRemove();
                break;
            case 'open-teams':
                animateNavigate(`/teams?ID=${notification.AdditionalData?.ID}`)
                handleRemove();
                break;
            case 'open-chats':
                animateNavigate(`/chat?ID=${notification.AdditionalData?.ID}`)
                handleRemove();
                break;
            default:
                console.log('Unknown action');
        }
    };

    return (
        <div onClick={handleNotificationClick}
            className={`notification__item notification__item--${notification.State} ${isClosing ? 'closing' : ''}`}
            style={{ maxHeight: isClosing ? '0px' : '100px', opacity: isClosing ? 0 : 1 }}
        >
            <div className="notification__item__header">
                <span className="notification__item__date">
                    {moment(notification.createdAt).format('DD/MM/YYYY HH:mm')}
                </span>
                <img
                    src="./images/closeBtn.svg"
                    alt="Close Notification"
                    className="notification__item__close"
                    onClick={(e) => {e.stopPropagation(); handleRemove()}}
                />
            </div>
            <p className="notification__item__content">{notification.Content}</p>
            <div className="notification__item__timer">
                <div
                    className="notification__item__timer-line"
                    style={{ width: `${timerWidth}%` }}
                ></div>
            </div>
        </div>
    );
};
export default Notifications