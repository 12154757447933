import polyfills from "./polyfills.js";
import { SSE } from "./sse.js";


/** @type {"dev" | "prod"} */
const env = "prod";

const rpcPath = "/_rpc";
const genericError = { status: "error", data: "RPC_CLIENT_ERROR" };
let curStore = null;

let baseURL = `http://lvh.me:5000${rpcPath}`;
if (env === "prod") baseURL = `https://teams.obscale.com:5000${rpcPath}`;

const safeDecode = str => {
    try {
        return decodeURI(str);
    } catch {
        return str;
    };
};

const parseData = (data) => {
    if (data === null || data === undefined) return data;
    switch (typeof data) {
        case "string": return safeDecode(data);
        case "number": return Number(data);
        case "object":
            if (data instanceof Array) {
                return data.map(item => parseData(item));
            } else {
                let final = {};
                Object.keys(data).forEach(item => {
                    final[item] = parseData(data[item]);
                });
                return final;
            };
        case "boolean": return (String(data) === "true") ? true : false;
        default: return data;
    };
};

const openSSEStream = (item, options, _serverOpenned = false) => new Promise(async r => {
    await new Promise(r => setTimeout(() => r(), 500)); // dont block main thread with this
    let answered = false;
    let badEventFired = false;
    let serverClosed = true;
    let serverOpenned = _serverOpenned;
    let autoRetry = options["subscribeReconnect"];

    const retryConnection = async () => {
        try {
            item._sse.close();
        } catch {};
        delete item["_sse"];
        delete item["_rpc_id"];

        return setTimeout(() => {
            openSSEStream(item, options, serverOpenned);
        }, 1000);
    };

    const closeServerManual = () => {
        try {
            serverClosed = false;
            item._sse?.close?.();
        } catch {};
    };

    const closeServerHandler = () => {
        autoRetry = false;
        closeServerManual();
    };

    try {
        item._sse = new SSE(baseURL, {
            method: "POST",
            payload: JSON.stringify(item),
            withCredentials: true,
            start: true,
            headers: {...(options?.headers ?? {}), "Content-Type": "application/json"}
        });
        item._sse.onmessage = e => {
            if (!answered) {
                answered = true;
                const _headers = (item?._sse?.xhr?.getAllResponseHeaders?.() ?? "").trim().split('\r\n').reduce((acc, current) => {
                    const [x,v] = current.split(': ');
                    return Object.assign(acc, { [x] : v });
                }, {});
                if (!_headers) _headers = {};
                if (typeof(_headers) !== "object" || Array.isArray(_headers)) _headers = {};
                item["headers"] = _headers;
                r(true);
            };
            if (e?.id && !item._rpc_id) {
                if (typeof(item?.subscribeOpenedCallback) === "function" && !serverOpenned) {
                    serverOpenned = true;
                    item.subscribeOpenedCallback(closeServerHandler, item);
                };
                item._rpc_id = e?.id;
            };
            if (!e?.data) return;

            let d = null;
            try {
                d = JSON.parse(e?.data);
            } catch { return; };
            if (!d?.json) return;

            item.subscribeDataCallback(d?.json, closeServerHandler, item);
        }
        item._sse.onerror = () => {
            if (!answered) {
                answered = false;
                r(false);
                if (autoRetry && serverOpenned) retryConnection();
                return;
            };

            if (autoRetry && serverOpenned) return retryConnection();
            if (badEventFired) return;
            badEventFired = true;
            if (typeof(item.subscribeClosedCallback) === "function") item.subscribeClosedCallback(serverClosed);
        };
        item._sse.onabort = () => {
            if (!answered) {
                answered = false;
                r(false);
                return;
            };

            if (autoRetry) autoRetry = false;
            if (badEventFired) return;
            badEventFired = true;
            if (typeof(item.subscribeClosedCallback) === "function") item.subscribeClosedCallback(serverClosed);
        };
    } catch {
        if (!answered) {
            answered = false;
            r(false);
        };

        if (serverOpenned && serverOpenned) {
            return retryConnection();
        };

        if (badEventFired) return;
        badEventFired = true;
        if (typeof(item.subscribeClosedCallback) === "function") item.subscribeClosedCallback(serverClosed);
    };
});

const createCall_stream = async (items, options) => {
    if (Array.isArray(items)) {
        let out = [];

        for (let item of items) {
            if (item?._sse) out.push({...item, status: "ok", data: "ALREADY_OPENED"});

            let stream = await openSSEStream(item, options);
            if (stream) {
                out.push({...item, status: "ok", data: null});
            } else {
                delete item["_rpc_id"];
                delete item["_sse"];
                out.push({...item, status: "error", data: "RPC_CLIENT_SUBSCRIBE_STREAM_ERROR"})
            };
        };
        
        return out;
    } else {
        if (items?._sse) return {...items, status: "ok", data: "ALREADY_OPENED"};
        let stream = await openSSEStream(items, options);
        if (stream) {
            return {...items, status: "ok", data: null};
        } else {
            delete items["_rpc_id"];
            delete items["_sse"];
            return {...items, status: "error", data: "RPC_CLIENT_SUBSCRIBE_STREAM_ERROR"};
        };
    };
};

const createCall_callback = (items, response) => {
    let prepareItemForCb = i => {
        let tmp = {...i};
        let allKeys = Object.keys(tmp);
        for (let key of allKeys) {
            if (![
                "status",
                "data",
                "meta",
                "method",
                "action",
                "params",
                "headers",
                "_id"
            ].includes(key)) delete tmp[key];
        };

        return tmp;
    };

    if (Array.isArray(items)) {
        if (response.status !== "ok") {
            for (let item of items)
                if (item.callback) item.callback(prepareItemForCb(response));
        } else {
            for (let item of items) {
                if (!item.callback) continue;
                let found = false;
                for (let res of response.data) {
                    if (res?._id === item?._id) {
                        item.callback(prepareItemForCb(res));
                        found = true;
                        break;
                    };
                };
                if (!found) item.callback({status: "error", data: `CLIENT_RPC_ID_NOT_FOUND`, action: item.action, method: item.method, _id: item._id});
            };
        };
    } else {

        if (items.callback) items.callback(prepareItemForCb(response));
    };
};

const getContentType = requestType => {
    switch (requestType) {
        case "json": return "application/json";
        case "formData": return "multipart/form-data";
        default: return "application/json";
    };
};

let _call_counter = 0;
const createCall = async (item, options) => {
    let streams = [];
    let requests = [];

    let toParse = item;
    if (!Array.isArray(toParse)) toParse = [toParse];
    for (let itemTmp of toParse) {
        // assign a counter
        _call_counter += 1;
        itemTmp.meta = {
            cc: _call_counter,
            original: itemTmp.meta
        };

        if (itemTmp?.action === "subscribe") {
            streams.push(itemTmp);
        } else {
            requests.push(itemTmp);
        };
    };
    if (streams.length === 0 && requests.length === 0) return {status: "error", data: "NOTHING_TO_PROCESS"};

    let streamsOut = [];
    let requestsOut = [];
    let hadRequestError = false;

    // handle requests
    if (requests.length > 0) {
        let batchItem = null;
        if (options.batchProgressCallback) {
            batchItem = {
                method: "batching.progress",
                action: "subscribe",
                subscribeDataCallback: (bd, bc) => {
                    options.batchProgressCallback(bd);

                    try { if (bd?.status !== "running") bc(); } catch {};
                }
            };
            await createCall_stream(batchItem, {});
            if (!batchItem?._rpc_id) batchItem = null;
        };

        let serverHeaders = {};
        let serverReq = await window.axios({
            method: "POST",
            url: baseURL,
            data: requests.length === 1 ? requests[0] : requests,
            headers: {
                ...(options?.headers ?? {}),
                "Content-Type": getContentType(options?.requestType),
                ...(batchItem?._rpc_id ? {
                    "rpc-batching-progress": batchItem._rpc_id
                } : {})
            },
            withCredentials: true
        }).then(res => {
            serverHeaders = res?.headers ?? {};
            return res.data;
        }).catch((e) => {
            serverHeaders = e?.response?.headers ?? {};
            return genericError;
        });
        if (!serverHeaders) serverHeaders = {};
        if (typeof(serverHeaders) !== "object" || Array.isArray(serverHeaders)) serverHeaders = {};

        if (serverReq?.status !== "ok") {
            for (let itemTmp of requests) {
                itemTmp["status"] = "error";
                itemTmp["data"] = serverReq?.data ?? "SERVER_ERROR";
                itemTmp["headers"] = serverHeaders
                requestsOut.push(itemTmp);
            };
            hadRequestError = true;
        } else {
            if (requests.length === 1) {
                requests[0]["status"] = serverReq["status"];
                requests[0]["data"] = serverReq["data"];
                requests[0]["headers"] = serverHeaders;
                requestsOut.push(requests[0]);
            } else {
                for (let itemTmp of requests) {
                    let found = false;
                    for (let item2 of serverReq.data) {
                        if (itemTmp?.meta?.cc === item2?.meta?.cc) {
                            itemTmp["status"] = item2["status"];
                            itemTmp["data"] = item2["data"];
                            itemTmp["headers"] = serverHeaders;
                            requestsOut.push(itemTmp);
                            found = true;
                            break;
                        };
                    };
                    if (found) continue;
                };
            };
        };
    };

    // handle streaming
    if (streams.length > 0 && !hadRequestError) streamsOut.push(...(await createCall_stream(streams, options)));

    // remove the meta.cc
    for (let itemTmp of [...requestsOut, ...streamsOut]) {
        if (itemTmp?.meta) {
            itemTmp["_id"] = itemTmp?.meta?.cc;
            itemTmp["meta"] = itemTmp?.meta?.original;
        };
    };

    if (requestsOut.length > 0) createCall_callback(requestsOut, {status: "ok", data: requestsOut});
    if (streamsOut.length > 0) createCall_callback(streamsOut, {status: "ok", data: streamsOut});

    let combined = [...requestsOut, ...streamsOut];
    if (Array.isArray(item)) {
        return {status: hadRequestError ? "error" : "ok", data: combined};
    } else {
        return combined[0];
    };
};

const setRequestFailed = (input, err) => {
    input["status"] = "error";
    input["data"] = err;

    return input;
};

const checkIfFilesExist = input => {
    const paramsChecker = (params) => {
        if (!params) return false;
        if (params instanceof File) return true;

        if (Array.isArray(params)) {
            for (let p of params) {
                if (paramsChecker(p)) return true;
            };
        } else if (typeof(params) === "object") {
            for (let key of Object.keys(params)) {
                if (paramsChecker(params[key])) return true;
            };
        };
        return false;
    };

    if (Array.isArray(input)) {
        for (let i of input) {
            if (paramsChecker(i)) return true;
        };
        return false;
    } else {
        return paramsChecker(input);
    };
};

const checkOptions = (opt, input) => {
    if (!opt) return {};
    if (typeof(opt) !== "object" || Array.isArray(opt)) opt = {};
    let tmp = {...opt};

    if (!tmp["return"]) tmp["return"] = "all";
    if (!["first", "last", "all"].includes(tmp["return"])) tmp["return"] = "all";

    if (!tmp["requestType"]) tmp["requestType"] = checkIfFilesExist(input) ? "formData" : "json";
    if (!["json", "formData"].includes(tmp["requestType"])) tmp["requestType"] = "json";

    if (tmp["subscribeReconnect"] === null || tmp["subscribeReconnect"] === undefined) tmp["subscribeReconnect"] = true;
    tmp["subscribeReconnect"] = !!tmp["subscribeReconnect"];

    if (!tmp["headers"]) tmp["headers"] = {};
    if (typeof(tmp["headers"]) !== "object" || Array.isArray(tmp["headers"])) tmp["headers"] = {};

    if (opt["batchProgressCallback"]) {
        if (typeof(opt["batchProgressCallback"]) !== "function") opt["batchProgressCallback"] = undefined;
    };

    return tmp;
};

/**
 * @typedef {Object} rocInputTypeMapFunctionArgs
 * @property {(meta: string) => rpcInputType} [rocInputTypeMapFunctionArgs.findByMeta] Finds a completed request by meta name
 * @property {(action: "call" | "subscribe" | "unsubscribe", method: string) => rpcInputType} [rocInputTypeMapFunctionArgs.findByMethod] Finds a completed request by method
 */

/**
 * @typedef {Object} rpcInputType
 * @property {"call" | "subscribe" | "unsubscribe"} [rpcInputType.action] Action that will be executed on the method
 * @property {string} rpcInputType.method Method that will be called
 * @property {Object | null} [rpcInputType.params] Parameters that will be passed to the method
 * @property {Object | null} [rpcInputType.args] [Same as params | interchangeable] Arguments that will be passed to the method
 * @property {Object | null} [rpcInputType.meta] Metadata associated with the request. Used only with the client to later indentify the response if there are multiple requests being made.
 * @property {string | null} [rpcInputType.bindToMeta] Should the request be chunked and bound to metadata. This will wait for all the parents to complete first before sending this request.
 * @property {{action: "call" | "subscribe" | "unsubscribe", method: string} | null} [rpcInputType.bindToMethod] Should the request be chunked and bound to method call. This will wait for all the parents to complete first before sending this request. Make sure to define action and method properly.
 * @property {(arg: {status: "ok" | "error", data: any}) => void} [rpcInputType.callback] Optional callback that will be fired when the request completes. Useful with batching.
 * @property {(fn: rocInputTypeMapFunctionArgs, item: rpcInputType) => rpcInputType} [rpcInputType.map] Allows the mapping of the element before execution. Useful with bindToMethod or bindToMeta
 * @property {(data: any, unsubscribe: () => any, item: rpcInputType) => null} [rpcInputType.subscribeDataCallback] Function that will get fired each time a subscribe event gets new data.
 * @property {(serverClosed: boolean) => null} [rpcInputType.subscribeClosedCallback] Function that will get fired when a subscription is closed. Parametar defines if the server or the client closed the connection.
 * @property {(unsubscribe: () => any, item: rpcInputType) => null} [rpcInputType.subscribeOpenedCallback] Function that will get fired when a subscription is opened successfully.
*/

/**
 * @typedef {Object} rpcOptionsType
 * @property {"first" | "last" | "all"} [rpcOptionsType.return] What elements are being returned. default is "all". This only works for batched requests. Useful if you only care about the last request being sent.
 * @property {"json" | "formData"} [rpcOptionsType.requestType] Wether to send the request as a JSON (default) or as a FormData encded string. Use FD wiehn uploading files.
 * @property {boolean} [rpcOptionsType.subscribeReconnect] Sholud the subscription be reconnected automatically after a failrue. Default is true
 * @property {Object} [rpcOptionsType.headers] Headers (if any) that will be sent along with the request
 * @property {(data = {status: "running" | "completed", progress: number, completed: number, remaining: number, total: number, maxConcurrent: number}) => void} [rpcOptionsType.batchProgressCallback] Callback that will be used to report back on batching progress. Useful if you want to get data back from backend. NOTE: when "bindTo" is used, you might get multiple progresses so be careful!
 */


const chunkRequests = (input, finished) => {
    let fullfillable = [];
    let notFullfillable = [];

    for (let item of input) {
        if (!item.bindToMeta && !item.bindToMethod) {
            fullfillable.push(item);
            continue;
        };

        if (item.bindToMeta) {
            let found = false;

            for (let item2 of finished) {
                if (item2?.["meta"] === item.bindToMeta && item2?.["status"] === "ok") {
                    found = true;
                    fullfillable.push(item);
                    break;
                };
            };
            if (!found) {
                notFullfillable.push(item);
            };
        };

        if (item.bindToMethod) {
            let found = false;
            for (let item2 of finished) {
                if (item2?.["action"] === item.bindToMethod.action && item2?.["method"] === item.bindToMethod.method && item2?.["status"] === "ok") {
                    found = true;
                    fullfillable.push(item);
                    break;
                };
            };
            if (!found) notFullfillable.push(item);
        };
    };

    return [fullfillable, notFullfillable];
};

const checkBinds = input => {
    for (let item of input) {
        if (item.bindToMeta) {
            let found = false;
            for (let item2 of input) {
                if (item2 === item) continue;
                if (item2["meta"] === item.bindToMeta) {
                    found = true;
                    break;
                };
            };
            if (!found) return false;
        };

        if (item.bindToMethod) {
            if (typeof(item.bindToMethod) !== "object" || Array.isArray(item.bindToMethod)) return false;
            if (!item.bindToMethod?.action || !item.bindToMethod?.method) return false;

            let found = false;
            for (let item2 of input) {
                if (item2 === item) continue;

                if (item.bindToMethod?.action === item2.action && item.bindToMethod?.method === item2.method) {
                    found = true;
                    break;
                };
            };
            if (!found) return false;
        };
    };


    return true;
};

const mapItem = (results, item) => {
    const findByMeta = str => {
        for (let item of results) {
            if (item?.["meta"] === str) return item;
        };
        return null;
    };
    const findByMethod = (action, method) => {
        if (!action || !method) return null;
        for (let item of results) {
            if (item?.["action"] === action && item?.["method"] === method) return item;
        };
        return null;
    };
    
    return [{
        findByMeta,
        findByMethod
    }, item];
};

const quickVerifyItemValidity = input => {
    if (!input) return false;
    if (!input["params"] && input["args"]) input["params"] = input["args"];
    if (!input["params"]) input["params"] = {};
    if (typeof(input["params"]) !== "object" || Array.isArray(input["params"])) input["params"] = {};
    if (typeof(input) !== "object" && !Array.isArray(input)) return false;
    if (input.callback && typeof(input.callback) !== "function") return false;
    if (input.map && typeof(input.map) !== "function") return false;
    if (input.action === "subscribe" && !input.subscribeDataCallback) return {status: "error", data: "RPC_CLIENT_EVENT_WITHOUT_CALLBACK"};
    if (input.subscribeDataCallback && typeof(input.subscribeDataCallback) !== "function") return genericError;

    if (!input["headers"]) input["headers"] = {};
    if (typeof(input["headers"]) !== "object" || Array.isArray(input["headers"])) input["headers"] = {};

    return true;
};

/**
 * 
 * @param {rpcInputType | rpcInputType[]} input 
 * @param {rpcOptionsType} options
 * @returns 
 */
const rpcClient = async (input, options = {}) => {
    // make sure that the polyfills are loaded
    await polyfills();

    if (!input) return genericError;

    if (Array.isArray(input)) {
        for (let item of input) {
            if (!item) return genericError;
            if (!item["params"] && item["args"]) item["params"] = item["args"];
            if (!item["params"]) item["params"] = {};
            if (typeof(item["params"]) !== "object" || Array.isArray(item["params"])) item["params"] = {};
            if (typeof(item) !== "object" || Array.isArray(item)) return genericError;
            if (item.callback && typeof(item.callback) !== "function") return genericError;
            if (item.map && typeof(item.map) !== "function") return genericError;
            if (item.action === "subscribe" && !item.subscribeDataCallback) return {status: "error", data: "RPC_CLIENT_EVENT_WITHOUT_CALLBACK"};
            if (item.subscribeDataCallback && typeof(item.subscribeDataCallback) !== "function") return genericError;

            if (!item["headers"]) item["headers"] = {};
            if (typeof(item["headers"]) !== "object" || Array.isArray(item["headers"])) item["headers"] = {};
        };
        options = checkOptions(options, input);
    } else {
        if (!input["params"] && input["args"]) input["params"] = input["args"];
        if (!input["params"]) input["params"] = {};
        if (typeof(input["params"]) !== "object" || Array.isArray(input["params"])) input["params"] = {};
        if (typeof(input) !== "object" && !Array.isArray(input)) return genericError;
        if (input.callback && typeof(input.callback) !== "function") return genericError;
        if (input.map && typeof(input.map) !== "function") return genericError;
        if (input.action === "subscribe" && !input.subscribeDataCallback) return {status: "error", data: "RPC_CLIENT_EVENT_WITHOUT_CALLBACK"};
        if (input.subscribeDataCallback && typeof(input.subscribeDataCallback) !== "function") return genericError;
        if (!input["method"]) return genericError;

        if (!input["headers"]) input["headers"] = {};
        if (typeof(input["headers"]) !== "object" || Array.isArray(input["headers"])) input["headers"] = {};

        options = checkOptions(options, input);

        if (input.map) {
            input = input.map(...mapItem([], input));
            if (!quickVerifyItemValidity(input)) return genericError;
        };
        return await createCall(input, options);
    };

    if (!checkBinds(input)) return {status: "error", data: "RPC_CLIENT_BIND_TO_NOT_FOUND"};

    let completed = [];
    let tmpToParse = input;
    while (tmpToParse.length > 0) {
        let chunks = chunkRequests(tmpToParse, completed);
        if (chunks[0].length === 0) {
            for (let tmp of chunks[1]) {
                completed.push(setRequestFailed(tmp, "RPC_CLIENT_BIND_NOT_FOUND_OR_ERROR"));
                createCall_callback([tmp], {status: "ok", data: completed});
            };
            break;
        };

        chunks[0] = chunks[0].map(c => {
            if (c.map) c = c.map(...mapItem(completed, c));
            return c;
        });
        for (let chk of chunks[0]) {
            if (!quickVerifyItemValidity(chk)) {
                return genericError;
            };
        };
        let tmpRes = await createCall(chunks[0], options);
        if (tmpRes.status === "ok") {
            completed.push(...tmpRes.data);
            tmpToParse = chunks[1];
            continue;
        } else {
            for (let tmp of [...chunks[0], ...chunks[1]]) {
                completed.push(setRequestFailed(tmp, tmp?.data ?? "RPC_CLIENT_BIND_NOT_FOUND_OR_ERROR"));
                createCall_callback([tmp], {status: "ok", data: completed});
            };
            break;
        };
    };

    let hadError = false;
    for (let item of completed) {
        if (item?.status !== "ok") {
            hadError = true;
            break;
        };
    };

    switch (options.return) {
        case "first":
            if (completed.length === 0) return {status: "error", data: "NOT_FOUND"};
            return completed[0];
        case "last":
            if (completed.length === 0) return {status: "error", data: "NOT_FOUND"};
            return completed[completed.length - 1];
        default: return {status: hadError ? "error" : "ok", data: completed, action: "batch"};
    };
};

export default rpcClient;
export const setReduxStore = st => curStore = st;
export const getReduxStore = () => curStore;