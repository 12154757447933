import "./index.scss";
import React from "react";

import rpcClient from "../../../modules/rpcClientModule";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { animateBox } from "../../../modules/componentAnimation";

import Spinner from "../../../components/customComponents/Spinner";

import languages from "../../../languages";


const MultiSelectUsers = props => {

    const languageSelector = useSelector(state => state?.siteFunctions?.language ?? 'en');

    const [selectedData, setSelectedData] = React.useState(props.selectedData ? props.selectedData : []);
    const [hoveredDataElem, setHoveredDataElem] = React.useState(null);
    const [hoveredDataSuggestedElem, setHoveredDataSuggestedElem] = React.useState(null);
    const [data, setData] = React.useState(props.data ? props.data : []);
    const [suggestedData, setSuggestedData] = React.useState(props.suggestedData ? props.suggestedData : []);
    const [selectedRole, setSelectedRole] = React.useState('');
    const [filteredData, setFilteredData] = React.useState(props.data ? props.data : [])

    const searchRef = React.useRef();
    const searchTimeoutRef = React.useRef(null);

    const addSelectedData = (item) => {
        let foundElem;
        foundElem = selectedData.find(elem => elem.ID === item.ID);

        if (!foundElem) {
            setSelectedData([...selectedData, item]);
        } else {
            setSelectedData(selectedData.filter(obj => obj.ID !== item.ID));
        }
    }

    const checkIfItemIsSelected = (id) => {
        let checker = selectedData.find(el => el.ID === id)
        if (checker) {
            return 'data--item--selected'
        }
    }

    const changeRoleFilter = (gender) => {
        if (!selectedRole) setSelectedRole(gender);
        if (selectedRole === gender) setSelectedRole('');
        if (selectedRole && selectedRole !== gender) setSelectedRole(gender)
    }

    const performSearch = () => {
        const searchValue = searchRef?.current?.value.toLocaleLowerCase();

        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }

        searchTimeoutRef.current = setTimeout(() => {
            if (searchValue) {
                const updatedData = data.filter((elem) => {
                    const searchTarget = `${elem.Username} ${elem.FirstName} ${elem.LastName}`.toLocaleLowerCase();
                    return searchTarget.includes(searchValue);
                });
                setFilteredData(updatedData);
            } else {
                setFilteredData(data);
            }
        }, 300);
    }


    return <div className="route__usersList__multiselect" >
        <div className="route__usersList__multiselect__inner" style={{ ...(props.style ?? {}), ...(props.popupStyle ?? {}) }}>

            <div className="route__usersList__multiselect__inner__genderBtns">
                <p onClick={() => changeRoleFilter('isAdmin')} style={{ borderColor: selectedRole === 'isAdmin' ? '#38ef7d' : 'white', color: selectedRole === 'isAdmin' ? '#38ef7d' : 'white' }}>{languages[languageSelector].admins}</p>
                <p onClick={() => changeRoleFilter('isManager')} style={{ borderColor: selectedRole === 'isManager' ? '#38ef7d' : 'white', color: selectedRole === 'isManager' ? '#38ef7d' : 'white' }}>{languages[languageSelector].managers}</p>
                <div class="form__group field">
                    <input type="input" class="form__field" ref={searchRef} onChange={performSearch} placeholder="Name" />
                    <label for="name" class="form__label">{languages[languageSelector].name}</label>
                </div>
            </div>

            {suggestedData && suggestedData.length ? <p className="route__usersList__multiselect__inner__suggestedHeadline"
                style={{ color: props.accent ? props.accent : '#fff' }}
            >Suggested:</p> : null}
            {suggestedData && suggestedData.length ? <div className="route__usersList__multiselect__inner__data"
                style={{ marginBottom: '20px' }}>
                {suggestedData.map((elem, index) => {
                    const isHovered = index === hoveredDataSuggestedElem;

                    return <div className={''}
                        style={{
                            color: props.accent ? props.accent : '#fff',
                            border: `1px solid ${props.accent}`,
                            boxShadow: isHovered ? `inset 0 0 8px 2px ${props.accent ? props.accent : null}` : 'none',
                        }}
                        onMouseEnter={(e) => { setHoveredDataSuggestedElem(index) }}
                        onMouseLeave={(e) => setHoveredDataSuggestedElem(null)}
                        onClick={(e) => { addSelectedData(elem); e.currentTarget.classList.contains("data--item--selected") ? e.currentTarget.classList.remove("data--item--selected") : e.currentTarget.classList.add("data--item--selected") }}
                    >
                        <p>{elem.name} </p>
                    </div>
                })}
            </div> : null}

            <div className="route__usersList__multiselect__inner__data">
                {filteredData.map((elem, index) => {
                    if (selectedRole && !elem.Flags[`${selectedRole}`]) return;
                    const isHovered = index === hoveredDataElem;
                    return <div className={`${checkIfItemIsSelected(elem.ID)}`}
                        style={{
                            color: props.accent ? props.accent : '#fff',
                            border: `1px solid ${props.accent}`,
                            boxShadow: isHovered ? `inset 0 0 8px 2px ${props.accent ? props.accent : null}` : 'none',
                        }}
                        onMouseEnter={(e) => { setHoveredDataElem(index) }}
                        onMouseLeave={(e) => setHoveredDataElem(null)}
                        onClick={(e) => { addSelectedData(elem); e.currentTarget.classList.contains("data--item--selected") ? e.currentTarget.classList.remove("data--item--selected") : e.currentTarget.classList.add("data--item--selected") }}
                    >
                        {elem.Username === 'rootAdmin' ? <p>{elem.Username}</p> : <p>{elem.FirstName} {elem.LastName}</p>}
                    </div>
                })}
            </div>

            <div className="route__usersList__createComment__wrap__btns">
                <p onClick={() => { props.onChange(selectedData); props.onClose() }}>
                    {/* <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" /> */}
                    <span style={{ color: 'rgb(64, 149, 255)' }}>{languages[languageSelector].select}</span>
                </p>
                <p onClick={props.onClose}>
                    <span style={{ color: '#EA3F3F' }}>{languages[languageSelector].cancel}</span>
                    {/* <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" /> */}
                </p>
            </div>

        </div>
    </div>
}


export default MultiSelectUsers