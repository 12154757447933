import "./index.scss";
import React from "react";

import axios from "axios";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import rpcClient from "../../modules/rpcClientModule";
import { updateTimestamp } from "../../actions/timestampActions";
import { updateStickySidebar } from "../../actions/siteFunctionsActions";

import SettingsModal from "../modals/SettingsModal";

import animateModule from "../../modules/animateModule";
import { animateBox } from "../../modules/componentAnimation";
import languages from "../../languages";

const Sidebar = (props) => {
    const curLocation = useLocation();
    const curNavigate = useNavigate();
    const curDispatch = useDispatch();

    const userDataSelector = useSelector(state => state?.userData ?? {});
    const notificationsSelector = useSelector(state => state?.notifications ?? {});
    const languageSelector = useSelector(state => state?.siteFunctions?.language ?? 'en');

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".root__content"));

    const [sidebarOpened, setSidebarOpened] = React.useState(false);
    const [sidebarHovered, setSidebarHovered] = React.useState(false);

    const [windowSize, setWindowSize] = React.useState(window.innerWidth);

    const checkSidebarActive = elem => {
        if (!elem) return curLocation.pathname === "/";
        return (curLocation.pathname.startsWith(`/${elem}`));
    };

    const onSidebarMouseEnter = () => setSidebarHovered(true);
    const onSidebarMouseLeave = () => setSidebarHovered(false); 

    React.useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    });

    React.useEffect(() => {
        curDispatch(updateStickySidebar(sidebarOpened))
    }, [sidebarOpened])

    const logout = () => {
        rpcClient({
            action: "call",
            method: "auth.logout",
        }).then(() => {
            curDispatch(updateTimestamp())
            animateNavigate("/login")
        })
    }


    return <div className={`component__sidebar ${(sidebarOpened || sidebarHovered) ? 'component__sidebar__opened' : ''} ${windowSize < 769 ? 'component__sidebar-mobile' : ''}`}
        onMouseEnter={onSidebarMouseEnter} onMouseLeave={onSidebarMouseLeave}
    >

        <div className="component__sidebar__top">

            <div style={{ marginBottom: '40px' }} onClick={() => setSidebarOpened(d => !d)} className={`component__sidebar__item`}>
                {(sidebarOpened || sidebarHovered) ? <>
                    <p className={`${(sidebarOpened || sidebarHovered) ? 'component__sidebar__item__opened' : ''} logoHeadline`}>ScaleTeam</p>
                    <img className='logoImg' src="./images/closeSidebarIcon.svg" />
                </> : <>
                    <img src="./images/openSidebarIcon.svg" />
                </>}
            </div>

            <div onClick={() => animateNavigate("/teams")} className={`component__sidebar__item ${checkSidebarActive("teams") ? "component__sidebar__item--active" : ""}`}>
                <img src="./images/teamsIcon.svg" />
                <p className={`${(sidebarOpened || sidebarHovered) ? 'component__sidebar__item__opened' : ''}`}>{languages[languageSelector].teams}</p>
                {notificationsSelector?.teamChannels?.length ? <div className='component__sidebar__item--dot'></div> : null}
            </div>
            <div onClick={() => animateNavigate("/chat")} className={`component__sidebar__item ${checkSidebarActive("chat") ? "component__sidebar__item--active" : ""}`}>
                <img src="./images/chatIcon.svg" />
                <p className={`${(sidebarOpened || sidebarHovered) ? 'component__sidebar__item__opened' : ''}`}>{languages[languageSelector].chats}</p>
                {notificationsSelector?.chatRooms?.isPrivateChat?.length ? <div className='component__sidebar__item--dot'></div> : null}
            </div>
            <div onClick={() => animateNavigate("/tasks")} className={`component__sidebar__item ${checkSidebarActive("tasks") ? "component__sidebar__item--active" : ""}`}>
                <img src="./images/tasksIcon.svg" />
                <p className={`${(sidebarOpened || sidebarHovered) ? 'component__sidebar__item__opened' : ''}`}>{languages[languageSelector].tasks}</p>
                {notificationsSelector?.tasks?.length ? <div className='component__sidebar__item--dot'></div> : null}
            </div>

            {/* admin and manager stuff below */}
            {(userDataSelector?.userData?.UserInfo?.Flags?.isAdmin || userDataSelector?.userData?.UserInfo?.Flags?.isManager) && <div>
                <p className="component__sidebar__top__line"></p>
            </div>}
            {userDataSelector?.userData?.UserInfo?.Flags?.isAdmin && <div onClick={() => animateNavigate("/admin-teams")} className={`component__sidebar__item ${checkSidebarActive("admin-teams") ? "component__sidebar__item--active" : ""}`}>
                <img src="./images/teamsIcon.svg" />
                <p className={`${(sidebarOpened || sidebarHovered) ? 'component__sidebar__item__opened' : ''}`}>{languages[languageSelector].manageTeams}</p>
            </div>}
            {userDataSelector?.userData?.UserInfo?.Flags?.isAdmin && <div onClick={() => animateNavigate("/admin-users")} className={`component__sidebar__item ${checkSidebarActive("admin-users") ? "component__sidebar__item--active" : ""}`}>
                <img src="./images/chatIcon.svg" />
                <p className={`${(sidebarOpened || sidebarHovered) ? 'component__sidebar__item__opened' : ''}`}>{languages[languageSelector].manageUsers}</p>
            </div>}
            {/* {(userDataSelector?.userData?.UserInfo?.Flags?.isAdmin || userDataSelector?.userData?.UserInfo?.Flags?.isManager) && <div onClick={() => animateNavigate("/admin-tasks")} className={`component__sidebar__item ${checkSidebarActive("admin-tasks") ? "component__sidebar__item--active" : ""}`}>
                <img src="./images/tasksIcon.svg" />
                <p className={`${(sidebarOpened || sidebarHovered) ? 'component__sidebar__item__opened':''}`}>ManageTasks</p>
            </div>} */}
            {/* end of admin and manager stuff below */}
        </div>
        <div className="component__sidebar__bottom">
            <div className={`component__sidebar__item`} onClick={() => animateBox(<SettingsModal />)}>
                <img src="./images/settingsIcon.svg" />
                <p className={`${(sidebarOpened || sidebarHovered) ? 'component__sidebar__item__opened' : ''}`}>{languages[languageSelector].settings}</p>
            </div>
            <div onClick={() => logout()} className={`component__sidebar__item`}>
                <img src="./images/chatIcon.svg" />
                <p className={`${(sidebarOpened || sidebarHovered) ? 'component__sidebar__item__opened' : ''}`}>{languages[languageSelector].logout}</p>
            </div>
        </div>

    </div>
};

export default Sidebar;