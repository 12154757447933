import "./index.scss";
import React from "react";

import rpcClient from "../../../modules/rpcClientModule";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { animateBox } from "../../../modules/componentAnimation";

import Spinner from "../../../components/customComponents/Spinner";

import MultiSelectUsers from "../MultiSelectUsersModal";

import languages from "../../../languages";


const CreateTeamModal = (props) => {

    const languageSelector = useSelector(state => state?.siteFunctions?.language ?? 'en');

    const [spinner, setSpinner] = React.useState(false)
    const [infoP, setInfoP] = React.useState();
    const teamNameRef = React.useRef();

    const [users, setUsers] = React.useState([])

    const [selectedUsers, setSelectedUsers] = React.useState(props.edit ? props.edit.Participants : []);

    const onClose = (force = false) => {
        if (spinner && !force) return;

        props.onClose();
    };

    const getUsers = () => {

        rpcClient({
            action: "call",
            method: "users.getAll",
            callback: d => setUsers(d)
        })
    };

    const addTeam = () => {
        if (!teamNameRef.current.value) return;
        setSpinner(true);

        let data = {
            Name: teamNameRef.current.value,
            Participants: selectedUsers
        }

        rpcClient({
            action: "call",
            method: "teams.addTeam",
            params: data
        }).then(res => {
            if (res.status === "ok") {
                props.onChange();
                props.onClose();
            }
            if (res.status === "error") {
                setInfoP(res.data);
            }
            setSpinner(false);
        })
    }


    const editTeam = (team) => {
        setSpinner(true);

        let data = {
            ID: team.ID,
            Name: teamNameRef.current.value,
            Participants: selectedUsers
        }

        rpcClient({
            action: "call",
            method: "teams.editTeam",
            params: data
        }).then(res => {
            if (res.status === "ok") {
                props.onChange();
                props.onClose();
            }
            if (res.status === "error") {
                setInfoP(res.data)
            }
            setSpinner(false)
        })
    }

    React.useEffect(() => {
        getUsers()
    }, [])


    return <div className="createTeamModal" onClick={() => props.onClose()}>
        <div className="createTeamModal__wrapper" onClick={(e) => e.stopPropagation()}>
            <p className="createTeamModal__wrapper__headline">{props.edit ? `${languages[languageSelector].edit}` : `${languages[languageSelector].add}`} {languages[languageSelector].team}</p>
            <p className="createTeamModal__wrapper__label">{languages[languageSelector].teamName}</p>
            <input placeholder={`${languages[languageSelector].teamName}`} ref={teamNameRef} defaultValue={props.edit ? props.edit.Name : ''} />
            <p className="createTeamModal__wrapper__addTeamMember" onClick={(e) => animateBox(e,
                <MultiSelectUsers
                    headline={`${languages[languageSelector].selectUsers}`}
                    theme="dark"
                    accent="#fff"
                    selectBy="ID"
                    data={users.data}
                    selectedData={selectedUsers ? selectedUsers : []}
                    onChange={e => setSelectedUsers(e)}
                    style={{ marginBottom: '20px' }}
                />)}>{languages[languageSelector].addTeamMembers}</p>
            <div className="createTeamModal__wrapper__btns">
                <div className="createTeamModal__wrapper__btns__btn" style={{ backgroundColor: '#1B1D21' }} onClick={onClose}>
                    <p>{languages[languageSelector].cancel}</p>
                </div>
                {
                    props.edit ? <div className="createTeamModal__wrapper__btns__btn" style={{ backgroundColor: '#6C5DD3' }} onClick={() => { editTeam(props.edit) }}>
                        {spinner ? <Spinner style={{ width: "24px", height: "24px" }} color="white" /> : <p>{languages[languageSelector].edit}</p>}
                    </div> : <div className="createTeamModal__wrapper__btns__btn" style={{ backgroundColor: '#6C5DD3' }} onClick={addTeam}>
                        {spinner ? <Spinner style={{ width: "24px", height: "24px" }} color="white" /> : <p>{languages[languageSelector].add}</p>}
                    </div>
                }

            </div>
            <p className="createTeamModal__wrapper__infoP" style={{
                opacity: infoP ? 1 : 0
            }}>{infoP}</p>
        </div>
    </div>
}


export default CreateTeamModal;