import "./index.scss";
import React from "react";
import axios, { all } from "axios";
import moment from "moment";
import rpcClient from "../../../modules/rpcClientModule";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import CreateTaskModal from "../../modals/CreateTaskModal";
import * as notificationsActions from "../../../actions/notificationsActions.js";
import Spinner from "../../customComponents/Spinner";
import { animateBox } from "../../../modules/componentAnimation";

import languages from "../../../languages.js";

const TaskSystem = (props) => {
  const curDispatch = useDispatch();
  const location = useLocation();
  const mainNavigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const taskID = queryParams.get('taskID');

  const userDataSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});
  const notificationsSelector = useSelector(state => state?.notifications ?? {});
  const languageSelector = useSelector(state => state?.siteFunctions?.language ?? 'en');

  const [spinner, setSpinner] = React.useState(false);
  const [filters, setFilters] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [currentTeamBuckets, setCurrentTeamBuckets] = React.useState([]);
  const [tasks, setTasks] = React.useState([]);
  const [listOpen, setListOpen] = React.useState(false);
  const [currentDraggedTaskID, setCurrentDraggedTaskID] = React.useState();
  const [isModalOpen, setIsModalOpen] = React.useState(false); 

  const newListItemName = React.useRef();

  const getCurrentTeamChannel = () => {
    if (!props.teamChannel) return;

    rpcClient({
      action: "call",
      method: "teamChannels.getTeamChannelByID",
      params: {
        ID: props.teamChannel.ID
      },
    }).then(teamChannel => {
      if (teamChannel?.status === 'ok') {
        let taskIntegration = teamChannel.data.Integrations.find(el => el.Type === 'taskSystem');
        if (!taskIntegration) {
          setCurrentTeamBuckets([`${languages[languageSelector].toDo}`, 
            `${languages[languageSelector].inProgress}`, 
            `${languages[languageSelector].reviewRequired}`, 
            `${languages[languageSelector].done}`, 
            `${languages[languageSelector].stuck}`
          ]);
          setTasks([]);
        } else {
          setCurrentTeamBuckets(taskIntegration.Buckets);
        }
      }
    });
  };

  const getAllTasks = () => {
    if (!props.teamChannel) return;

    rpcClient({
      action: "call",
      method: "tasks.getAll",
      params: {
        filters: filters.length ? filters : [],
        orders: [{ name: "updatedAt", order: "desc" }],
        allTasks: true,
        teamChannelID: props.teamChannel.ID
      },
    }).then(tasks => {
      if (tasks.status === 'ok' && tasks.data.length) {
        setTasks(tasks.data);
      }
    }).catch(err => {
    });
  };

  const getUsers = () => {
    rpcClient({
      action: "call",
      method: "users.getAll",
      params: {
        filters: filters.length ? filters : []
      },
      callback: d => setUsers(d)
    });
  };

  const modifyBuckets = (action, item) => {
    if (!item) return;

    rpcClient({
      action: "call",
      method: "teamChannels.editIntegrationsBuckets",
      params: {
        filters: filters.length ? filters : [],
        ID: props.teamChannel.ID,
        item: item,
        action: action
      },
    }).then(res => {
      if (res.status === 'ok') {
        let buckets = res.data.Integrations.find(el => el.Type === 'taskSystem').Buckets;
        setCurrentTeamBuckets(buckets);
        if (newListItemName.current.value) {
          newListItemName.current.value = '';
        }
      }
    });
  };

  const openTaskFromURL = () => {
    if (!taskID || !currentTeamBuckets || !tasks?.length || isModalOpen) return; 
    const taskToOpen = tasks.find(task => task.ID === taskID);
    if (taskToOpen) {
      setIsModalOpen(true); 
      removeFromNotifications(taskID);
      curDispatch(notificationsActions.removeTaskNotification(taskID));
      animateBox(<CreateTaskModal
        teamChannelID={props?.teamChannel?.ID}
        allStatuses={currentTeamBuckets}
        Status={taskToOpen.Status}
        edit={taskToOpen}
        onChange={getAllTasks}
        setIsModalOpen={() => setIsModalOpen(false)}
      />);
      mainNavigate({
        pathname: location.pathname, 
      }, { replace: true });
    }
  };

  const removeFromNotifications = (id) => {
    rpcClient({
      action: "call",
      method: "tasks.removeFromNotifications",
      params: {
        ID: id
      },
    });
  };

  const onDragStart = (e, task, status) => {
    setCurrentDraggedTaskID(task.ID);
    e.dataTransfer.setData("task", JSON.stringify(task));
    e.dataTransfer.setData("status", status);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e, newStatus) => {
    if (!currentDraggedTaskID) return;

    const prevStatus = e.dataTransfer.getData("status");

    if (prevStatus === newStatus) return;

    rpcClient({
      action: "call",
      method: "tasks.changeTaskStatus",
      params: {
        ID: currentDraggedTaskID,
        Status: newStatus
      },
    }).then(modifiedTask => {
      setCurrentDraggedTaskID('');
      getAllTasks();
    });
  };

  const getUsersInitials = (id) => {
    if (!id || !users) return '';
    const user = users?.data?.find(el => el.ID === id);
    return user ? user.FirstName.charAt(0) + user.LastName.charAt(0) : '';
  };

  const getUserFullName = (id) => {
    if (!id || !users) return '';
    const user = users?.data?.find(el => el.ID === id);
    return user ? `${user.FirstName} ${user.LastName}` : '';
  };

  const handleKeyPressBucket = (e) => {
    if (!newListItemName.current.value) return;
    if (e.key === 'Enter') {
      modifyBuckets('add', newListItemName.current.value);
      newListItemName.current.value = '';
    }
  };

  React.useEffect(() => {
    let unsub = () => undefined;

    rpcClient({
      action: "subscribe",
      method: "notifications.subscribe",
      subscribeDataCallback: (d) => {
        if (d.update === 'tasks') {
          curDispatch(notificationsActions.addTaskNotification(d.AdditionalData));
          getAllTasks();
        }
      },
      subscribeOpenedCallback: u => {
        unsub = u;
      }
    });

    return () => {
      unsub();
    };
  }, [props.teamChannel]);

  React.useEffect(() => {
    getCurrentTeamChannel();
    getAllTasks();
  }, [props.teamChannel]);

  React.useEffect(() => {
    if (!userDataSelector.Flags.isAdmin) return;
    getUsers();
  }, [props.teamChannel]);

  React.useEffect(() => {
    if (listOpen && newListItemName.current) {
      newListItemName.current.focus();
    }
  }, [listOpen]);

  React.useEffect(() => {
    if(!taskID || !currentTeamBuckets || !tasks.length) return;
    openTaskFromURL();
  }, [taskID, currentTeamBuckets, tasks]);

  return (
    <div className="component__taskSystem">
      {currentTeamBuckets.map((bucket) => (
        <div
          key={bucket}
          className="component__taskSystem__card"
          onDragOver={onDragOver}
          onDrop={(e) => onDrop(e, bucket)}
        >
          <p className="component__taskSystem__card__headline">{bucket}
            {bucket !== 'To Do' && bucket !== 'In Progress' && bucket !== 'Review Required' && bucket !== 'Done' && bucket !== 'Stuck' &&
              <img src="./images/closeBtn.svg" onClick={() => modifyBuckets('remove', bucket)} />
            }
          </p>
          <div className="component__taskSystem__card__content">
            {tasks.filter((task) => task.Status === bucket).map((task) => {
              let isInNotifications = notificationsSelector.tasks.some(
                notificationTask => notificationTask.taskID === task.ID
              );
              return (
                <div
                  key={task.id}
                  className="component__taskSystem__card__content__item"
                  draggable
                  onDragStart={(e) => onDragStart(e, task, bucket)}
                  onClick={() => {
                    if (!isModalOpen) {
                      setIsModalOpen(true);
                      curDispatch(notificationsActions.removeTaskNotification(task.ID));
                      removeFromNotifications(task.ID);
                      animateBox(<CreateTaskModal
                        teamChannelID={props?.teamChannel?.ID}
                        allStatuses={currentTeamBuckets}
                        Status={bucket}
                        edit={task}
                        onChange={getAllTasks}
                        setIsModalOpen={() => { setIsModalOpen(false) }}
                      />);
                    }
                  }}
                >
                  {isInNotifications && <div className="component__taskSystem__card__content__item--dot" />}
                  <div className="component__taskSystem__card__content__item__top">
                    <p className="component__taskSystem__card__content__item__top__name">{task.TaskName}</p>
                    <p className={`component__taskSystem__card__content__item__top__priority priority__${task?.Priority}`}>{task.Priority}</p>
                  </div>
                  <div className="component__taskSystem__card__content__item__bot">
                    {task?.CheckList?.length ? (
                      <div className="component__taskSystem__card__content__item__bot__item">
                        <img src="./images/listIcon.svg" alt="list icon" />
                        <p>{task.CheckList.length}</p>
                      </div>
                    ) : null}
                    {task?.Comments?.length ? (
                      <div className="component__taskSystem__card__content__item__bot__item">
                        <img src="./images/commentsIcon.svg" alt="comments icon" />
                        <p>{task.Comments.length}</p>
                      </div>
                    ) : null}
                    {task?.Attachments?.length ? (
                      <div className="component__taskSystem__card__content__item__bot__item">
                        <img src="./images/attachmentsIcon.svg" alt="attachments icon" />
                        <p>{task.Attachments.length}</p>
                      </div>
                    ) : null}
                    {task?.AssignedTo && users ? (
                      <div
                        className="component__taskSystem__card__content__item__bot__item"
                        style={{ marginLeft: "auto" }}
                      >
                        <p className="component__taskSystem__card__content__item__bot__item__assignedto">
                          {getUsersInitials(task.AssignedTo)}
                        </p>
                        <span className="tooltip-text">{getUserFullName(task.AssignedTo)}</span>
                      </div>
                    ) : null}
                    
                  </div>
                </div>
              );
            })}
            <div onClick={() => animateBox(<CreateTaskModal
              teamChannelID={props?.teamChannel?.ID}
              allStatuses={currentTeamBuckets}
              Status={bucket}
              onChange={getAllTasks}
              setIsModalOpen={() => { setIsModalOpen(false) }}
            />)}
              className="component__taskSystem__card__add">
              <img src="./images/plusIcon.svg" />
              <p>{languages[languageSelector].addTask}</p>
            </div>
          </div>
        </div>
      ))}

      {(userDataSelector.Flags.isAdmin || userDataSelector.Flags.isManager) && <div onClick={() => setListOpen(d => !d)} className={`component__taskSystem__addList`}>
        <img src="./images/plusIcon.svg" />
        <p>{languages[languageSelector].addList}</p>
        <div className={`component__taskSystem__addList__inner ${listOpen ? 'innerOpen' : ''}`}>
          <div className="component__taskSystem__addList__inner__top">
            <input onKeyDown={handleKeyPressBucket} ref={newListItemName} onClick={(e) => e.stopPropagation()} placeholder={`${languages[languageSelector].listName}`} />
          </div>
          <div className="component__taskSystem__addList__inner__bottom">
            <p onClick={(e) => { e.stopPropagation(); modifyBuckets('add', newListItemName?.current?.value) }}>{languages[languageSelector].addList}</p>
            <img src='./images/closeBtn.svg' />
          </div>
        </div>
      </div>}

      {spinner && <Spinner />}
    </div>
  );
};

export default TaskSystem;
