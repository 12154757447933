const languages = {
    en: {
        english: "English",
        bosnian: "Bosnian",
        lightTheme: 'Light Theme',
        darkTheme: 'Dark Theme',
        appearance: 'Appearance',
        changePassword: 'Change password',
        password: 'Password',
        newPassword: 'New password',
        confirmPassword: 'Confirm Password',
        saveChanges: 'Save Changes',
        lastName: 'Last Name',
        firstName: 'First Name',
        uploadNewImage: 'Upload new image',
        editProfile: 'Edit Profile',
        teams: "Teams",
        chats: "Chats",
        tasks: "Tasks",
        manageTeams: "Manage Teams",
        manageUsers: "Manage Users",
        settings: "Settings",
        logout: "Logout",
        editTeam: 'Edit Team',
        addTeamChannel: 'Add Team Channel',
        addTeam: 'Add Team',
        edit: 'Edit',
        add: 'Add',
        cancel: 'Cancel',
        team: 'Team',
        teamName: 'Team Name',
        selectUsers: 'Select users',
        addTeamMembers: 'Add Team Members',
        teamChannel: 'Team Channel',
        teamChannelMembers: 'Team Channel Members',
        teamChannelName: 'Team Channel Name',
        whoHasRightToChat: 'Who has right to chat ?',
        adminsOnly: 'Admins Only',
        managementOnly: 'Management Only',
        everyone: 'Everyone',
        admins: 'Admins',
        managers: 'Managers',
        name: 'Name',
        select: 'Select',
        chats: 'Chats',
        chatName: 'Chat name (for group chat)',
        editChat: 'Edit a chat',
        startChat: 'Start a chat',
        selectUsers: 'Select Users',
        addChatMembers: 'Add Chat Members',
        tasks: 'Tasks',
        toDo: 'To Do',
        inProgress: 'In Progress',
        reviewRequierd: 'Review Required',
        done: 'Done',
        stuck: 'Stuck',
        addTask: 'Add Task',
        addList: 'Add List',
        listName: 'List name'
    },
    bs: {
        english: "Engleski",
        bosnian: "Bosanski",
        lightTheme: 'Svjetla tema',
        darkTheme: 'Tamna tema',
        appearance: 'Izgled',
        changePassword: 'Promjeni lozinku',
        password: 'Lozinka',
        newPassword: 'Nova lozinka',
        confirmPassword: 'Potvrdi lozinku',
        saveChanges: 'Spremi promjene',
        lastName: 'Prezime',
        firstName: 'Ime',
        uploadNewImage: 'Dodaj sliku',
        editProfile: 'Uredu Profil',
        teams: "Timovi",
        chats: "Poruke",
        tasks: "Zadaci",
        manageTeams: "Upravljanje Timovima",
        manageUsers: "Upravljanje Korisnicima",
        settings: "Postavke",
        logout: "Odjava",
        editTeam: 'Uredi Tim',
        addTeamChannel: 'Dodaj Kanal',
        addTeam: 'Dodaj Tim',
        edit: 'Uredi',
        add: 'Dodaj',
        cancel: 'Obustavi',
        team: 'Tim',
        teamName: 'Ime Tima',
        selectUsers: 'Odaberi članove',
        addTeamMembers: 'Dodaj članove tima',
        teamChannel: 'Kanal',
        teamChannelMembers: 'Članovi Kanala',
        teamChannelName: 'Ime Kanala',
        whoHasRightToChat: 'Ko ima pravo da piše poruke ?',
        adminsOnly: 'Samo Admini',
        managementOnly: 'Menadžment',
        everyone: 'Svi',
        admins: 'Admini',
        managers: 'Menadžeri',
        name: 'Ime',
        select: 'Odaberi',
        chats: 'Poruke',
        chatName : 'Ime razgovora (za grupni razgovor)',
        editChat: 'Uredu razgovor',
        startChat: 'Pokreni razgovor',
        selectUsers: 'Odaberite članove',
        addChatMembers: 'Dodaj članove razgovora',
        tasks: 'Zadatci',
        toDo: 'Za uraditi',
        inProgress: 'U toku',
        reviewRequierd: 'Potrebna recenzija',
        done: 'Završeno',
        stuck: 'Zaglavljeno',
        addTask: 'Dodaj Zadatak',
        addList: 'Dodaj Listu',
        listName: 'Ime liste'
    }
};

export default languages;