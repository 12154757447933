import React from 'react';
import "./index.scss";
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import rpcClient from '../../../modules/rpcClientModule';
import * as timestmapActions from "../../../actions/timestampActions";
import * as notificationsActions from "../../../actions/notificationsActions";

import Spinner from "../../customComponents/Spinner";
import AdvancedDropdown from "../../customComponents/AdvancedDropdown"


export default function CreateTaskModal(props) {

  const userInfo = useSelector(state => state?.userData?.userData?.UserInfo ?? {});
  const curDispatch = useDispatch();

  const [spinner, setSpinner] = React.useState(false);
  const [filters, setFilters] = React.useState([]);
  const [infoP, setInfoP] = React.useState();

  const [attachments, setAttachments] = React.useState(props?.edit?.Attachments ? props.edit.Attachments.map(el => {
    el.existed = true;
    return el
  }) : []);

  const [checkListItems, setCheckListItems] = React.useState(props?.edit?.CheckList ? props.edit.CheckList : []);
  const [checkedPercentage, setCheckedPercentage] = React.useState(0);

  const [selectedDate, setSelectedDate] = React.useState(null);
  const [dateTimestamp, setDateTimestamp] = React.useState(props?.edit?.Deadline ? props.edit.Deadline : null);

  const [selectedPriority, setSelectedPriority] = React.useState(props?.edit?.Priority ? props.edit.Priority : 'High');
  const [allStatuses, setAllStatuses] = React.useState(props.allStatuses ? props.allStatuses : ['To Do', 'In Progress', 'Review Required', 'Done', 'Stuck'])
  const [selectedStatus, setSelectedStatus] = React.useState(props?.edit?.Status ? props.edit.Status : props.Status ? props.Status : 'To Do');

  const [selectedAssignedTo, setSelectedAssignedTo] = React.useState(props?.edit?.AssignedTo ? props.edit.AssignedTo : userInfo?.ID);
  const [selectedReviewBy, setSelectedReviewBy] = React.useState(props?.edit?.ReviewBy ? props.edit.ReviewBy : userInfo.ID);

  const [users, setUsers] = React.useState([]);
  const [comments, setComments] = React.useState([]);


  const taskNameRef = React.useRef();
  const initialTaskNameRef = React.useRef();
  const taskDescriptionRef = React.useRef();
  const initialTaskDescriptionRef = React.useRef();
  const checkListItemRef = React.useRef();
  const commentRef = React.useRef();


  const createTask = async () => {
    if (spinner) return;
    setSpinner(true);
    if (!props.edit && !selectedAssignedTo) return setInfoP('Please select to who will task be assigned');
    if (!props.edit && !taskNameRef?.current?.value) return setInfoP('Please enter name of the task');

    let finalAttachments = props.edit ? attachments?.filter(f => f?.existed) : [];

    if (attachments.length) {

      for (let item of attachments) {
        if (item?.existed) continue
        await rpcClient({
          action: "call",
          method: "staticContent.images.upload",
          params: {
            ImageFile: item?.file,
            ImageName: item?.file?.name,
            ImageTag: 'taskImage'
          },
          callback: c => {
            if (c?.status === 'ok') finalAttachments.push({ preview: c.data, fileName: c.params.ImageName })
          }
        })
      }

      let data = {
        TeamChannelID: props?.teamChannelID,
        AssignedTo: selectedAssignedTo,
        ReviewBy: selectedReviewBy,
        TaskName: taskNameRef?.current?.value ? taskNameRef?.current?.value : initialTaskNameRef?.current?.textContent,
        Description: taskDescriptionRef?.current?.value ? taskDescriptionRef?.current?.value : initialTaskDescriptionRef?.current?.textContent ? initialTaskDescriptionRef?.current?.textContent : null,
        Status: selectedStatus,
        Priority: selectedPriority,
        Attachments: finalAttachments,
        CheckList: checkListItems,
        Deadline: dateTimestamp,
        NotificationsFor: [`${selectedAssignedTo}`, `${selectedReviewBy}`]
      }

      rpcClient({
        action: "call",
        method: props.edit ? "tasks.editTask" : "tasks.addTask",
        params: props.edit ? { ...data, ID: props.edit.ID } : data
      }).then((res) => {
        if (res.status === 'ok') {
          props.onChange();
          setSpinner(false);
          props.setIsModalOpen();
          props.onClose();
        }
      })

      return
    }
    

    let data = {
      TeamChannelID: props?.teamChannelID,
      AssignedTo: selectedAssignedTo,
      ReviewBy: selectedReviewBy,
      TaskName: taskNameRef?.current?.value ? taskNameRef?.current?.value : initialTaskNameRef?.current?.textContent,
      Description: taskDescriptionRef?.current?.value ? taskDescriptionRef?.current?.value : initialTaskDescriptionRef?.current?.textContent ? initialTaskDescriptionRef?.current?.textContent : null,
      Status: selectedStatus,
      Priority: selectedPriority,
      Attachments: finalAttachments,
      CheckList: checkListItems,
      Deadline: dateTimestamp,
      NotificationsFor: [`${selectedAssignedTo}`, `${selectedReviewBy}`]
    }

    rpcClient({
      action: "call",
      method: props.edit ? "tasks.editTask" : "tasks.addTask",
      params: props.edit ? { ...data, ID: props.edit.ID } : data
    }).then((res) => {
      if (res.status === 'ok') {
        props.onChange();
        setSpinner(false);
        props.setIsModalOpen();
        props.onClose();
      }
    })
  }

  const getAllUsers = () => {
    rpcClient({
      action: "call",
      method: "users.getAll",
      params: {
        filters: filters.length ? filters : []
      },
      callback: d => setUsers(d)
    })
  }

  const getAllComments = () => {
    if(!props?.edit?.ID) return;
    rpcClient({
      action: "call",
      method: "comments.getAll",
      params: {
        TaskID: props?.edit?.ID
      },
    }).then(comms => {
      if(comms.status === 'ok'){
        setComments(comms.data)
      }
    })
  }

  const getSomeUsersFirstLastName = (id) => {
    if(!users || !users?.data?.length) return;
    let wantedUser = users?.data?.find(el => el.ID === id);
    return `${wantedUser?.FirstName} ${wantedUser?.LastName}`;
  }


  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    const newImages = files.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
      uploaded: false,
    }));

    setAttachments((prevFiles) => [...prevFiles, ...newImages]);
  };

  const addCheckListItem = () => {
    if(!userInfo) return;
    if (!checkListItemRef.current.value) return;
    setCheckListItems([...checkListItems, { checked: false, value: checkListItemRef.current.value, createdBy: userInfo.ID }])
    checkListItemRef.current.value = ''
  }

  const addComment = () => {
    if(!userInfo) return;
    if (!commentRef.current.value) return;
    setSpinner(true);

    rpcClient({
      action: "call",
      method: "comments.create",
      params: {
        Content: commentRef.current.value,
        TaskName: taskNameRef?.current?.value ? taskNameRef?.current?.value : initialTaskNameRef?.current?.value,
        TaskID: props?.edit ? props.edit.ID : null,
        TeamChannelID: props?.teamChannelID,
      }
    }).then((res) => {
      if(res.status === 'ok'){
        setComments([...comments, res.data])
      }
    }).finally(()=> {
      setSpinner(false);
    })

    commentRef.current.value = ''
  }
  const handleKeyDownAddCheckListItem = (event) => {
    if (event.key === 'Enter') {
      addCheckListItem();
    }
  }
  const handleKeyDownAddComment = (event) => {
    if (event.key === 'Enter') {
      addComment();
    }
  }
  const handleCheckChange = (value) => {
    setCheckListItems(prevItems =>
      prevItems.map(item =>
        item.value === value ? { ...item, checked: !item.checked } : item
      )
    );
  };
  const handleDateChange = (e) => {
    const dateString = e.target.value; 
    setSelectedDate(dateString);

    const selectedDateTimestamp = new Date(dateString).getTime();

    setDateTimestamp(selectedDateTimestamp)
  };
  const handleRemoveCheckListItem = (value) => {
    setCheckListItems(prevItems => prevItems.filter(item => item.value !== value));
  };

  const openImageInNewTab = (url) => {
    window.open(url, '_blank');
  };
  const handleRemoveAttachmentItem = (event, preview) => {
    event.stopPropagation();
    setAttachments(prevAttachments => prevAttachments.filter(item => item.preview !== preview));
  };

  React.useEffect(() => {
    getAllUsers();
  }, [])

  React.useEffect(() => {
    getAllComments();
  }, [props?.edit])

  React.useEffect(() => {
    if (checkListItems.length) {
      const totalItems = checkListItems.length;
      const checkedItems = checkListItems.filter(item => item.checked).length;
      const percentage = Math.round((checkedItems / totalItems) * 100);
      setCheckedPercentage(percentage);
    } else {
      setCheckedPercentage(0);
    }
  }, [checkListItems]);

  React.useEffect(() => {
    if (props?.edit?.Deadline) {
      const formattedDate = new Date(props.edit.Deadline).toISOString().split('T')[0];
      setSelectedDate(formattedDate);
    }
  }, [props?.edit?.Deadline]);

  React.useEffect(() => {
    if (taskDescriptionRef.current) {
      taskDescriptionRef.current.style.height = 'auto';
      taskDescriptionRef.current.style.height = `${taskDescriptionRef.current.scrollHeight}px`;
      taskDescriptionRef.current.style.maxHeight = `60vh`;
    }
  }, []);

  return <div className='component__createTaskModal' onClick={() => {props.setIsModalOpen(); props.onClose()}}>
    <div className='component__createTaskModal__wrapper' onClick={(e) => e.stopPropagation()}>
      <img className='component__createTaskModal__wrapper__close' onClick={() => { props.setIsModalOpen(); props.onClose() }} src='./images/closeBtn.svg' />
      <div style={{ marginBottom: '20px' }} className='component__createTaskModal__wrapper__header'>
        <p>{props?.edit ? 'Edit' : 'Create'} Task</p>
      </div>

      <div className='component__createTaskModal__wrapper__grid'>
        <div className='component__createTaskModal__wrapper__grid__item'>
          <p>Status</p>
          <AdvancedDropdown
            showSearch={false}
            data={(allStatuses).map(column => {
              return {
                key: column,
                name: column,
                value: column
              }
            })}
            selected={(() => {
              let tmp = allStatuses;
              return tmp.indexOf(tmp.find(t => t === selectedStatus));
            })()}
            onChange={e => {
              if (selectedStatus !== e?.value) return setSelectedStatus(e?.value);
            }}
          />
        </div>
        <div className='component__createTaskModal__wrapper__grid__item'>
          <p>Priority</p>
          {props?.edit?.CreatedBy === userInfo?.ID || userInfo?.Flags?.isAdmin || userInfo?.Flags?.isManager ? <AdvancedDropdown
            showSearch={false}
            data={(['High', 'Medium', 'Low']).map(column => {
              return {
                key: column,
                name: column,
                value: column
              }
            })}
            selected={(() => {
              let tmp = ['High', 'Medium', 'Low'];
              return tmp.indexOf(tmp.find(t => t === selectedPriority));
            })()}
            onChange={e => {
              if (selectedPriority !== e?.value) return setSelectedPriority(e?.value);
            }}
          /> : <p className='component__createTaskModal__wrapper__viewText' style={{width: '100%', marginLeft: '0px'}}>{props?.edit?.Priority}</p>}
        </div>
      </div>

      {users?.status === 'ok' && <div className='component__createTaskModal__wrapper__grid'>
        <div className='component__createTaskModal__wrapper__grid__item'>
          <p>{props.edit ? 'Assigned' : 'Assign'} to:</p>
          {props?.edit?.CreatedBy === userInfo?.ID || userInfo?.Flags?.isAdmin || userInfo?.Flags?.isManager ? <AdvancedDropdown
            showSearch={true}
            data={(users?.data).map(column => {
              let name = column.FirstName + ' ' + column.LastName
              return {
                key: column.ID,
                name: name,
                value: column.ID
              }
            })}
            selected={(() => {
              let tmp = users?.data;
              return tmp.indexOf(tmp.find(t => t.ID === selectedAssignedTo));
            })()}
            onChange={e => {
              if (selectedAssignedTo !== e?.value) return setSelectedAssignedTo(e?.value);
            }}
          /> : <p className='component__createTaskModal__wrapper__viewText' style={{width: '100%', marginLeft: '0px'}}>{getSomeUsersFirstLastName(props?.edit?.AssignedTo)}</p>}
        </div>
        <div className='component__createTaskModal__wrapper__grid__item'>
          <p>Review by:</p>
          {userInfo?.Flags?.isAdmin || userInfo?.Flags?.isManager ? <AdvancedDropdown
            showSearch={true}
            data={(users?.data).map(column => {
              let name = column.FirstName + ' ' + column.LastName
              return {
                key: column.ID,
                name: name,
                value: column.ID
              }
            })}
            selected={(() => {
              let tmp = users?.data;
              return tmp.indexOf(tmp.find(t => t.ID === selectedReviewBy));
            })()}
            onChange={e => {
              if (selectedReviewBy !== e?.value) return setSelectedReviewBy(e?.value);
            }}
          /> : <p className='component__createTaskModal__wrapper__viewText' style={{width: '100%', marginLeft: '0px'}}>{getSomeUsersFirstLastName(props?.edit?.ReviewBy)}</p>}
        </div>
        
      </div>}


      <div className='component__createTaskModal__wrapper__grid'>
        <div className='component__createTaskModal__wrapper__grid__item'>
          <p>Deadline:</p>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="date"
              value={selectedDate}
              onChange={handleDateChange}
              style={{ padding: '6px 12px' }}
            />
          </div>
        </div>
        <div className='component__createTaskModal__wrapper__grid__item'>
          <p>Created by:</p>
          <p className='component__createTaskModal__wrapper__viewText' style={{height: '38px', width: '100%', marginLeft: '0px'}}>{getSomeUsersFirstLastName(props?.edit?.CreatedBy)}</p>
        </div>
      </div>


      <p className='component__createTaskModal__wrapper__label'>Task Name</p>
      {props?.edit && props?.edit?.CreatedBy === userInfo?.ID || userInfo?.Flags?.isAdmin || userInfo?.Flags?.isManager ?
        <input className='component__createTaskModal__wrapper__input' placeholder='Add Task Name' defaultValue={props?.edit?.TaskName} ref={taskNameRef} /> : 
        props?.edit ? <p ref={initialTaskNameRef} className='component__createTaskModal__wrapper__viewText'>{props?.edit?.TaskName}</p> : 
        <input className='component__createTaskModal__wrapper__input' placeholder='Add Task Name' defaultValue={props?.edit?.TaskName} ref={taskNameRef} />
      }

      <p className='component__createTaskModal__wrapper__label'><img src='./images/listIcon.svg' /> Description</p>
      {props?.edit && props?.edit?.CreatedBy === userInfo?.ID || userInfo?.Flags?.isAdmin || userInfo?.Flags?.isManager ? 
      <textarea className='component__createTaskModal__wrapper__input' defaultValue={props?.edit?.Description} placeholder='Add Task Description' ref={taskDescriptionRef} /> 
      : props?.edit ? <p ref={initialTaskDescriptionRef} className='component__createTaskModal__wrapper__viewText'>{props?.edit?.Description}</p> : 
      <textarea className='component__createTaskModal__wrapper__input' defaultValue={props?.edit?.Description} placeholder='Add Task Description' ref={taskDescriptionRef} />}

      <p className='component__createTaskModal__wrapper__label'><img src='./images/attachmentsIcon.svg' /> Attachments</p>
      {attachments.length ? attachments.map(el => {
        return <div className='component__createTaskModal__wrapper__attachmentItem' onClick={() => openImageInNewTab(el.preview)}>
          <img src={el.preview} alt={el?.file?.name ? el?.file?.name : el?.fileName} className='component__createTaskModal__wrapper__attachmentItem__image' />
          <p>{el?.file?.name ? el?.file?.name : el?.fileName}</p>
          {props?.edit?.CreatedBy === userInfo?.ID || userInfo?.Flags?.isAdmin || userInfo?.Flags?.isManager ?
            <img className='component__createTaskModal__wrapper__attachmentItem__removeItem' src='./images/closeBtn.svg' onClick={(event) => handleRemoveAttachmentItem(event, el.preview)} />
            : null}
        </div>
      }) : null}

      {props?.edit?.CreatedBy === userInfo?.ID || userInfo?.Flags?.isAdmin || userInfo?.Flags?.isManager ? <p className='component__createTaskModal__wrapper__add'>
        <img src='./images/plusIcon.svg' /> Add Attachment
        <input
          type="file"
          multiple
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            opacity: 0,
            cursor: 'pointer',
          }}
          onChange={handleFileUpload}
        />
      </p> : null}

      <p className='component__createTaskModal__wrapper__label'><img src='./images/checkListIcon.svg' /> Check list</p>
      {checkListItems.length ? <div className='component__createTaskModal__wrapper__checkListBar'>
        <p>{checkedPercentage}%</p>
        <div className='component__createTaskModal__wrapper__checkListBar__wrapper'>
          <div className='component__createTaskModal__wrapper__checkListBar__wrapper__inner' style={{ width: `${checkedPercentage}%` }}></div>
        </div>
      </div> : null}

      {checkListItems.length ? checkListItems.map(el => {
        return <div className='component__createTaskModal__wrapper__checkListItem' onClick={() => handleCheckChange(el.value)}>
          <input checked={el.checked} type='checkbox' />
          <p>{el.value}</p>
          {el.checked && <img className='component__createTaskModal__wrapper__checkListItem__checkedImage' src='./images/checkboxChecked.svg' />}
          {el?.createdBy === userInfo?.ID || userInfo?.Flags?.isAdmin || userInfo?.Flags?.isManager ? 
            <img className='component__createTaskModal__wrapper__checkListItem__removeItem' src='./images/closeBtn.svg' onClick={() => handleRemoveCheckListItem(el.value)} /> 
            : null}
        </div>
      }) : null}

      <input className='component__createTaskModal__wrapper__input' style={{ marginTop: '6px' }} onKeyDown={handleKeyDownAddCheckListItem} placeholder='Add Check List Item' ref={checkListItemRef} /> 
      <p className='component__createTaskModal__wrapper__add' onClick={() => addCheckListItem()}><img src='./images/plusIcon.svg' /> Add Check List Item</p> 
      
      {props.edit && <p className='component__createTaskModal__wrapper__label' style={{marginBottom: '16px '}}><img src='./images/commentsIcon.svg' /> Comments</p>}
      {props.edit && comments.map(el => {
        return <div className='component__createTaskModal__wrapper__comment'>
          <div className='component__createTaskModal__wrapper__comment__left'>
            {el?.Image ? <img src={el.Image} /> : <p>{el.Initials}</p>}
          </div>
          <div className='component__createTaskModal__wrapper__comment__right'>
            <p>{el.Name} - {el.Content}</p>
            <span>{moment(el.createdAt).format('DD/MM/YYYY [at] HH:mm')}</span>
          </div>
        </div> 
      })}
      {props.edit && <input className='component__createTaskModal__wrapper__input' style={{ marginTop: '6px' }} onKeyDown={handleKeyDownAddComment} placeholder='Add Comment' ref={commentRef} /> }
      <div className='component__createTaskModal__wrapper__grid'>
        <div className='component__createTaskModal__wrapper__grid__item'>
          {props.edit && <p className='component__createTaskModal__wrapper__add' onClick={() => addComment()}><img src='./images/plusIcon.svg' /> Add Comment</p> }
        </div>
      </div>

      <p className='component__createTaskModal__wrapper__saveBtn' onClick={() => createTask()}>{spinner ? <Spinner color='white' style={{ width: '16px', height: '16px' }} /> : `${props?.edit ? 'Edit' : 'Add'} Task`}</p>

      {infoP && <p className='component__createTaskModal__wrapper__infoP'>{infoP}</p>}
    </div>
  </div>
}