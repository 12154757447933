import "./index.scss";
import React from "react";

import axios from "axios";
import moment from "moment";
import rpcClient from "../../modules/rpcClientModule";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import * as notificationsActions from "../../actions/notificationsActions.js";

import ChatWrapper from "../../components/integrations/Chat";
import Spinner from "../../components/customComponents/Spinner";
import CreateTeamModal from "../../components/modals/CreateTeamModal";
import CreateTeamChannelModal from "../../components/modals/CreateTeamChannelModal";

import animateModule from "../../modules/animateModule";
import { animateBox } from "../../modules/componentAnimation";

import languages from "../../languages"

const Teams = (props) => {
    const curLocation = useLocation();
    const curNavigate = useNavigate();

    const curDispatch = useDispatch();

    const userInfo = useSelector(state => state?.userData?.userData?.UserInfo ?? {});
    const userDataSelector = useSelector(state => state?.userData ?? {});
    const notificationsSelector = useSelector(state => state?.notifications ?? {});
    const languageSelector = useSelector(state => state?.siteFunctions?.language ?? 'en');

    const [filters, setFilters] = React.useState([]);

    const [teams, setTeams] = React.useState([]);
    const [openedTeamID, setOpenedTeamID] = React.useState('');
    const [openedTeamChannel, setOpenedTeamChannel] = React.useState();

    const [activeIntegration, setActiveIntegration] = React.useState('chatRoom');
    const [activeChatRoom, setActiveChatRoom] = React.useState('');

    const [teamChannels, setTeamChannels] = React.useState();
    const [currentTeamChannel, setCurrentTeamChannel] = React.useState();

    const getAllTeams = () => {
        rpcClient({
            action: "call",
            method: "teams.getAll",
            params: {
                filters: filters.length ? filters : [],
                orders: [{name: "updatedAt", order: "desc"}]
            },
            callback: d => setTeams(d)
        })
    };


    const getTeamChannels = () => {
        if (!openedTeamID) return;
        rpcClient({
            action: "call",
            method: "teamChannels.getAll",
            params: {
                filters: [
                    { name: "TeamID", op: "eq", value: openedTeamID }
                ],
                orders: [{ name: "createdAt", order: "asc" }]
            }
        }).then((teamChannels) => {
            if (teamChannels.status !== 'ok') return;

            setTeamChannels(teamChannels.data);

            if (teamChannels.data.length > 0) changeTeamChannel(teamChannels.data[0]);
        })
    };

    const changeTeamChannel = (elem) => {
        setCurrentTeamChannel(elem)
        if(Array.isArray(elem)){
            let final = elem.find(el => el.Type === 'chatRoom')
            setOpenedTeamChannel({Name: final?.Name, ID: final.ID})
        }else{
            setOpenedTeamChannel({Name: elem.Name, ID: elem.ID})
        }
    }

    const getChatRoomFromIntegration = (integration) => {
        let int = integration.find(el => el.Type === 'chatRoom');
        if (int) {
            setActiveChatRoom(int)
        }
    }

    const removeFromNotifications = (id) => {
        rpcClient({
            action: "call",
            method: "teamChannels.removeFromNotifications",
            params: {
                ID: id
            }
        })
    };

    React.useEffect(() => {
        getAllTeams();
    }, []);

    React.useEffect(() => {
        getTeamChannels();
    }, [openedTeamID]);

    React.useEffect(() => {
        let unsub = () => undefined;

        rpcClient({
            action: "subscribe",
            method: "notifications.subscribe",
            subscribeDataCallback: (d, item) => {
                try{
                    if(d.update === 'teams'){
                        getAllTeams()
                    }
                }catch{}
            },
            subscribeOpenedCallback: u => {
                unsub = u;
            }
        })
        return () => {
            try{
                unsub()
            }catch{}
        }
    }, [])


    return <div className={`component__teams`}>
        <div className="component__innerNavbar">
            <div className="component__innerNavbar__head">
                <p className="component__innerNavbar__head__header">{languages[languageSelector].teams}</p>
                {userInfo?.Flags?.isAdmin && <p title={`${languages[languageSelector].addTeam}`} onClick={(e) => animateBox(e, <CreateTeamModal user={userDataSelector} onChange={getAllTeams} />)} className="component__innerNavbar__headAdd"><img src="./images/icon_add.svg" /></p>}
            </div>
            {teams.status === 'ok' ? teams?.data?.map(el => {
                if(!el.channels.length) return;
                return (
                    <div className={`component__innerNavbar__item ${openedTeamID === el.ID ? 'component__innerNavbar__item__opened' : ''}`}
                    onClick={() => { 
                        setOpenedTeamID(el.ID); 
                        getChatRoomFromIntegration(el?.channels[0].Integrations); 
                        curDispatch(notificationsActions.removeTeamChannelNotification(el?.channels[0].ID));
                        removeFromNotifications(el?.channels[0].ID)
                    }}>
                        <div className="component__innerNavbar__item__top">
                            {
                                (() => {
                                    for(let item of notificationsSelector.teamChannels){
                                        if(item.teamID === el.ID){
                                            return <div className="component__innerNavbar__item__top--dot"></div>
                                        }
                                    }
                                })()
                            }
                            <div className="component__innerNavbar__item__top__left">
                                <img src="./images/logo.svg" />
                            </div>
                            <div className="component__innerNavbar__item__top__right">
                                <p className="component__innerNavbar__item__top__right__name">{el.Name} ({el.Participants.length})</p>
                                <div>
                                    {userInfo?.Flags?.isAdmin && <p title={`${languages[languageSelector].editTeam}`} onClick={(e) => {e.stopPropagation(); animateBox(e, <CreateTeamModal edit={el} user={userDataSelector} onChange={getAllTeams} />)}} className="component__innerNavbar__item__top__right__editTeam"><img src="./images/icon_edit.svg" /></p>}
                                    {userInfo?.Flags?.isAdmin && <p title={`${languages[languageSelector].addTeamChannel}`} onClick={(e) => animateBox(e, <CreateTeamChannelModal user={userDataSelector} teamID={el?.ID} onChange={() => {
                                    if (props.onChange) {
                                        props.onChange();
                                    }
                                        getTeamChannels();
                                    }}  />)} className="component__innerNavbar__headAdd"><img src="./images/icon_add.svg" /></p>}
                                </div>
                            </div>
                        </div>
                        <div className={`component__innerNavbar__item__bottom ${openedTeamID === el.ID ? 'component__innerNavbar__item__bottom__active' : ''}`}>
                            {teamChannels && teamChannels.length && teamChannels.map(elem => {
                                return (
                                    <div className={`component__innerNavbar__item__bottom__item ${openedTeamChannel?.ID === elem.ID ? 'component__innerNavbar__item__bottom__item__active' : ''}`}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            changeTeamChannel(elem);
                                            getChatRoomFromIntegration(elem.Integrations);
                                            curDispatch(notificationsActions.removeTeamChannelNotification(elem.ID));
                                            removeFromNotifications(elem.ID)
                                        }}
                                    >
                                        {
                                            (() => {
                                                for(let item of notificationsSelector.teamChannels){
                                                    if(item.teamChannelID === elem.ID){
                                                        return <div className="component__innerNavbar__item__bottom__item--dot"></div>
                                                    }
                                                }
                                            })()
                                        }
                                        <p>{elem.Name}</p>
                                        {userInfo?.Flags?.isAdmin && <p title="Edit Team Channel" onClick={(e) => animateBox(e, <CreateTeamChannelModal edit={elem} user={userDataSelector} teamID={el?.ID} onChange={() => {
                                        if (props.onChange) {
                                            props.onChange();
                                        }
                                            getTeamChannels();
                                        }}  />)}><img src="./images/icon_edit.svg" /></p>}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            }) : <Spinner color="#fff" style={{marginLeft: 'auto', marginRight: 'auto'}}/> }
        </div>
        {activeIntegration === 'chatRoom' && <ChatWrapper 
                                                key={activeChatRoom.ID}
                                                setActiveIntegration={setActiveIntegration} 
                                                chatImage={currentTeamChannel?.Image ? currentTeamChannel.Image : './images/logo.svg'}
                                                chatName={currentTeamChannel?.Name}
                                                integrations={currentTeamChannel?.Integrations}
                                                participants={currentTeamChannel?.Participants}
                                                chatRoomID={activeChatRoom.ID} 
                                                onChange={getAllTeams} 
                                            />}
    </div>
};

export default Teams;